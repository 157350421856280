import { useState, useEffect } from "react";
import { getLoggedinUser } from "../helpers/api_helper";

const useProfile = () => {
  const userProfileSession = getLoggedinUser();

  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/profile', {
          credentials: 'include',
        });
        const data = await res.json();

        if (data.valid) {
          setUserProfile({ email: data.email });
        } else {
          setUserProfile(null);
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        setUserProfile(null);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  return { userProfile, loading };

};

export { useProfile };
