import {React,useState,useEffect} from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Button,Modal,ModalHeader } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';



export default function Communication() {
    const [templates, setTemplates] = useState([]);
    const [type, setType] = useState('Single');
    const [products, setProducts] = useState([]);
    const [message, setMessage] = useState('');
    const [filterBy, setFilterby] = useState('All');
    const [phone, setPhone] = useState('');
    const [clients, setClients] = useState([]);
    const [selectedProduct, setSelectedproduct] = useState('');
    const [productClients, setProductclients] = useState([]);
    const [selectedTemplate, setSelectedtemplate] = useState('')

    useEffect(()=>{
        const fetchTemplates =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getTemplates');
            const data = await res.json();
            setTemplates(data)

        }

        const fetchClients =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getClients');
            const data = await res.json();
            setClients(data)

        }

        const fetchProducts =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getProducts');
            const data = await res.json();
            setProducts(data)

        }

        
        fetchTemplates();
        fetchClients();
        fetchProducts();
        

    },[])


    useEffect(()=>{
        const fetchData = async()=>{
            const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getProductclients?product=${selectedProduct}`);
            const data = await res.json();
            setProductclients(data)
        }

        fetchData();

        


    },[selectedProduct])

    useEffect(()=>{
        const toggle2 =async(e)=>{
            const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getThistemplate?id=${selectedTemplate}`);
            const data = await res.json();
            setMessage(data.content)

    
        }
        toggle2()


    }, [selectedTemplate])


    const sendMessage =async()=>{
        toast.error('Sorry! No SMS gateway has been integrated. Contact support!')
    }





  return (
    <div className='pagesContainer'>
    <ToastContainer />
         <Breadcrumbs title="Newjo" breadcrumbItem='Communications' />
         <div className="card">
            

            <div className="messageContainer">
            <h5>Send message</h5>
            <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Send to" onChange={(e)=>{setType(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Send to</option>
                        <option value='Single'>Single</option>
                        <option value='Bulk'>Bulk</option>
                        
                    </Form.Select>
            </FloatingLabel>
            {type == 'Bulk' &&
                <>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Filter recipient" onChange={(e)=>{setFilterby(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Filter recipient</option>
                        <option value='All'>All clients</option>
                        <option value='Product'>Product</option>
                        <option value='Renewals'>Upcoming renewals</option>
                    </Form.Select>
                </FloatingLabel>
                {filterBy == 'All' &&
                <>
                    <p>Contacts: {clients.length}</p>
                </>
                
                }
                {filterBy == 'Product' &&
                <>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Products" onChange={(e)=>{setSelectedproduct(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select product</option>
                        {products.map((val)=>{
                            return(
                                <option value={val.ProductDescription}>{val.ProductDescription}</option>
                            )
                          
                        })}
                    </Form.Select>
                </FloatingLabel>
                <p>Contacts: {productClients.length}</p>
                    
                </>
                
                }

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Templates" onChange={(e)=>{setSelectedtemplate(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option value=''>None</option>
                        {templates.map((val)=>{
                            return(
                                <option value={val.id}>{val.title}</option>
                            )
                          
                        })}
                    </Form.Select>
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingTextarea" label="Message" id='mes'>
                <Form.Control 
                    as="textarea" 
                    className='inputSearchs' 
                    value={message} 
                    placeholder="Mesage" 
                    rows={10} 
                    
                    onChange={(e) => setMessage(e.target.value)} 
                />
                </FloatingLabel>

                <Button onClick={sendMessage} color='success' style={{marginTop:'2%'}}>SEND MESSAGE</Button>


 
                </>
            
            }

            {type == 'Single' &&
                <>
                
                
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Phone number" onChange={(e)=>{setPhone(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={phone}  type="text" placeholder="Phone" />
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Templates" onChange={(e)=>{setSelectedtemplate(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option value=''>None</option>
                        {templates.map((val)=>{
                            return(
                                <option value={val.id}>{val.title}</option>
                            )
                          
                        })}
                    </Form.Select>
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingTextarea" label="Message" id='mes'>
                <Form.Control 
                    as="textarea" 
                    className='inputSearchs' 
                    value={message} 
                    placeholder="Mesage" 
                    rows={10} 
                    
                    onChange={(e) => setMessage(e.target.value)} 
                />
                </FloatingLabel>

                <Button onClick={sendMessage} color='success' style={{marginTop:'2%'}}>SEND MESSAGE</Button>


 
                </>
            
            }
            </div>
         </div>
    </div>
  )
}
