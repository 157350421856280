import {React,useState,useEffect} from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Table from 'react-bootstrap/Table';

export default function Sharedpolicies() {
    document.title = "Shared policies | Newjo Insurance Agency";

    const [policies, setPolicies] = useState([]);

    useEffect(()=>{
        const fetchPolicies =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getSharedpolicies');
            const data = await res.json();
            setPolicies(data)

        }

        
        fetchPolicies();
        

    },[])




  return (
    <div className='pagesContainer'>
         <Breadcrumbs title="Newjo" breadcrumbItem={`Share policies(${policies.length})`} />
          <div className="card">
         
          <div className="table-responsive">
                <Table striped bordered hover>

                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Policy Number</th>
                            <th scope="col">Client</th>
                            <th scope="col">Product</th>
                            <th scope="col">Insurer</th>
                            <th scope="col">status</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {policies.map((val,index)=>{
                            
                        return(
                        <tr>
                            <td>{index+1}</td>       
                            <td><button className='tableButton' value={val.id}>{val.policyNumber}</button></td>
                            <td>{val.client}</td>
                            <td>{val.product}</td>
                            <td>{val.insurer}</td>
                            <td>{val.status}</td>
                        </tr>
                            )                
                        })}
                    </tbody>
                </Table>
            </div>
          </div>
    </div>
  )
}
