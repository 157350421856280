import {React, useState,useEffect }from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Table from 'react-bootstrap/Table';
import { Button,Modal,ModalHeader,Label,Input } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default function Reportsfinance() {
    document.title = "Report-finance | Newjo Insurance Agency";

    const [policies, setPolicies] = useState([])
    const[start,setStart] = useState('');
    const[end,setEnd] = useState('');
    const[insurer, setInsurer] = useState('');
    const[insurers, setInsurers] = useState([]);
    const[product, setProduct] = useState('');
    const[products, setProducts] = useState([]);

    useEffect(()=>{
        const fetchPolicies =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getPolicies');
            const data = await res.json();
            setPolicies(data)

        }

        const fetchCompanies =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getCompanies');
            const data = await res.json();
            setInsurers(data)

        }
        const fetchProducts =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getProducts');
            const data = await res.json();
            setProducts(data)

        }

        
        fetchPolicies();
        fetchCompanies();
        fetchProducts();
        

    },[])

    const handleDateChange = (e) => {
        const dateTime = e.target.value;
        const millisecondsSinceEpoch = new Date(dateTime).getTime();
        setStart(millisecondsSinceEpoch);
    
      };
      const handleDateChange2 = (e) => {
        const dateTime = e.target.value;
        const millisecondsSinceEpoch = new Date(dateTime).getTime();
        setEnd(millisecondsSinceEpoch);
       
      };


      const exportToPdf = () => {
        const doc = new jsPDF();
    
        // Add a title to the PDF
        doc.text("Newjo_Report", 20, 10);
    
        // Extract table headers and rows from the DOM
        const table = document.getElementById("dataTable");
        const headers = Array.from(
          table.querySelectorAll("thead tr th")
        ).map((header) => header.textContent);
    
        const rows = Array.from(table.querySelectorAll("tbody tr")).map((row) =>
          Array.from(row.querySelectorAll("td")).map((cell) => cell.textContent)
        );
    
        // Generate the PDF table
        doc.autoTable({
          head: [headers],
          body: rows,
          startY: 20,
        });
    
        // Save the PDF
        doc.save("Newjo_Finance_report.pdf");
      };


    


  return (
    <div className='pagesContainer'>
        <Breadcrumbs title="Newjo" breadcrumbItem='Finance Report' />
          <div className="card">

          <div className="filterBar">
            <div className="flt">
            <div className="mb-4" id='mb'>
                <Label className="form-label">From date</Label>
                <Input
                    name="text"
                    className="form-control"
                    placeholder="Search order"
                    type="datetime-local"
                    onChange={handleDateChange}
                    // value={email}

                />
                
            </div>
            <div className="mb-4" id='mb'>
                <Label className="form-label">To date</Label>
                <Input
                    name="text"
                    className="form-control"
                    placeholder="Search order"
                    type="datetime-local"
                    onChange={handleDateChange2}
                    // value={email}

                />
                
            </div>

            </div>

            <div className="flt">
            <div className="mb-4" id='mb'>
                <Label className="form-label">Insurer</Label>
                <select name="" id="" className="form-control" onChange={(e)=>{setInsurer(e.target.value)}}>
                    <option selected disabled>Select Insurer</option>
                    <option value=''>All</option>
                    {insurers.map((val)=>{
                        return(
                            <option value={val.companyName}>{val.companyName}</option>
                        )
                    })}      
                   
                </select>
                
            </div>
            <div className="mb-4" id='mb'>
                <Label className="form-label">Product</Label>
                <select name="" id="" className="form-control" onChange={(e)=>{setProduct(e.target.value)}}>
                    <option selected disabled>Select product</option>
                    <option value=''>All</option>
                    {products.map((val)=>{
                        return(
                            <option value={val.ProductDescription}>{val.ProductDescription}</option>
                        )
                    })}      
                   
                </select>
                
            </div>
           
            
            </div>
           
           
        </div>

        <div className='addButton'>
            <Button color='success' onClick={exportToPdf}>Export to PDF</Button>
        </div>



          <div className="table-responsive">
                <Table striped bordered hover id="dataTable">

                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Date</th>
                            <th scope="col">Policy Number</th>
                            <th scope="col">Client</th>
                            <th scope="col">Insurer</th>
                            <th scope="col">Product</th>
                            <th scope="col">Gross Premium(KES)</th>
                            <th scope="col">Commission(KES)</th>
                            <th scope="col">Received(KES)</th>
                            <th scope="col">Bonus(KES)</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {policies.map((val,index)=>{
                            const date = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.date)
                          

                        if(start == '' && end == '' && insurer == '' && product == ''){
                            return(
                                <tr>
                                    <td>{index+1}</td> 
                                    <td>{date}</td>         
                                    <td>{val.policyNumber}</td>
                                    <td>{val.client}</td>
                                    <td>{val.insurer}</td>
                                    <td>{val.product}</td>
                                    <td>{parseFloat(val.grossPremium).toFixed(2)}</td>
                                    <td>{parseFloat(val.standardCommission).toFixed(2)}</td>
                                    <td>{parseFloat(val.received).toFixed(2)}</td>
                                    <td>{parseFloat(val.bonus).toFixed(2)}</td>

                                </tr>
                            )

                        }

                        if(start < val.date && end > val.date && insurer == '' && product == '' ){
                            return(
                                <tr>
                                    <td>{index+1}</td> 
                                    <td>{date}</td>         
                                    <td>{val.policyNumber}</td>
                                    <td>{val.client}</td>
                                    <td>{val.insurer}</td>
                                    <td>{val.product}</td>
                                    <td>{parseFloat(val.grossPremium).toFixed(2)}</td>
                                    <td>{parseFloat(val.standardCommission).toFixed(2)}</td>

                                </tr>
                            )
                        }
                        if(start < val.date && end > val.date && insurer == val.insurer && product == ''){
                            return(
                                <tr>
                                    <td>{index+1}</td> 
                                    <td>{date}</td>         
                                    <td>{val.policyNumber}</td>
                                    <td>{val.client}</td>
                                    <td>{val.insurer}</td>
                                    <td>{val.product}</td>
                                    <td>{parseFloat(val.grossPremium).toFixed(2)}</td>
                                    <td>{parseFloat(val.standardCommission).toFixed(2)}</td>

                                </tr>
                            )

                        }
                        if(start == '' && end == '' && insurer == val.insurer && product == '' ){
                            return(
                                <tr>
                                    <td>{index+1}</td> 
                                    <td>{date}</td>         
                                    <td>{val.policyNumber}</td>
                                    <td>{val.client}</td>
                                    <td>{val.insurer}</td>
                                    <td>{val.product}</td>
                                    <td>{parseFloat(val.grossPremium).toFixed(2)}</td>
                                    <td>{parseFloat(val.standardCommission).toFixed(2)}</td>

                                </tr>
                            )

                        }

                        if(start < val.date && end > val.date && insurer == val.insurer && product == val.product ){
                            return(
                                <tr>
                                    <td>{index+1}</td> 
                                    <td>{date}</td>         
                                    <td>{val.policyNumber}</td>
                                    <td>{val.client}</td>
                                    <td>{val.insurer}</td>
                                    <td>{val.product}</td>
                                    <td>{parseFloat(val.grossPremium).toFixed(2)}</td>
                                    <td>{parseFloat(val.standardCommission).toFixed(2)}</td>

                                </tr>
                            )

                        }
                        if(start == '' && end == '' && insurer == '' && product == val.product ){
                            return(
                                <tr>
                                    <td>{index+1}</td> 
                                    <td>{date}</td>         
                                    <td>{val.policyNumber}</td>
                                    <td>{val.client}</td>
                                    <td>{val.insurer}</td>
                                    <td>{val.product}</td>
                                    <td>{parseFloat(val.grossPremium).toFixed(2)}</td>
                                    <td>{parseFloat(val.standardCommission).toFixed(2)}</td>

                                </tr>
                            )

                        }
                        if(start == '' && end == '' && insurer == val.insurer && product == val.product ){
                            return(
                                <tr>
                                    <td>{index+1}</td> 
                                    <td>{date}</td>         
                                    <td>{val.policyNumber}</td>
                                    <td>{val.client}</td>
                                    <td>{val.insurer}</td>
                                    <td>{val.product}</td>
                                    <td>{parseFloat(val.grossPremium).toFixed(2)}</td>
                                    <td>{parseFloat(val.standardCommission).toFixed(2)}</td>

                                </tr>
                            )

                        }

                        if(start < val.date && end > val.date && insurer == '' && product == val.product){
                            return(
                                <tr>
                                    <td>{index+1}</td> 
                                    <td>{date}</td>         
                                    <td>{val.policyNumber}</td>
                                    <td>{val.client}</td>
                                    <td>{val.insurer}</td>
                                    <td>{val.product}</td>
                                    <td>{parseFloat(val.grossPremium).toFixed(2)}</td>
                                    <td>{parseFloat(val.standardCommission).toFixed(2)}</td>

                                </tr>
                            )

                        }

                      
                       
                            
                        })}
                    </tbody>
                </Table>
            </div>

          </div>
    </div>
  )
}
