import {React,useState,useEffect} from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Table from 'react-bootstrap/Table';
import { Button,Modal,ModalHeader } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';


export default function Covers() {
    document.title = "Cover types | Newjo Insurance Agency";

    const [covertypes, setCovertypes] = useState([]);
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [coverType, setCovertype] = useState();
    const [coverDescription, setCoverdescription] = useState('')
    const [selectedCover, setSelectedcover] = useState('')

    useEffect(()=>{

        const fetchCovertypes = async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getCovertypes');
            const data = await res.json();
            setCovertypes(data)
        }

        fetchCovertypes();

    },[])

    const fetchCovertypes = async()=>{
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/getCovertypes');
        const data = await res.json();
        setCovertypes(data)
    }


    const toggle =()=>{
        setModal(!modal)
    }

    const toggle2 = async(e)=>{
        const id = e.target.id;
        setSelectedcover(id);

        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getCovertype?id=${id}`);
        const data = await res.json();
        setCovertype(data.CoverTypeDescription)
        setCoverdescription(data.CoverTypeCode)

        setModal2(!modal2)

    }

    const submitCover =async()=>{
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/submitCovertype',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({coverType,coverDescription}),
            credentials:"include"
        })

        const data = await res.json();
        const {success} = data;
        if(success == true){
            toast.success('Cover type has been added')
            fetchCovertypes();
            setModal(false)
        }
        else{
            toast.error('Something went wrong!')
        }

    }

    const updateCover =async()=>{
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/updateCovertype',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({coverType,coverDescription,selectedCover}),
            credentials:"include"
        })

        const data = await res.json();
        const {success} = data;
        if(success == true){
            toast.success('Changes saved!')
            fetchCovertypes();
            setModal2(false)
        }
        else{
            toast.error('Something went wrong!')
        }

    }

    const deleteCover =async()=>{
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/deleteCovertype?id=${selectedCover}`);
        const data = await res.json();
        const {success} = data;
        if(success == true){
            toast.success('Cover type deleted!')
            fetchCovertypes();
            setModal2(false)
        }
        else{
            toast.error('Something went wrong!')
        }


    }


  return (
    <div className='pagesContainer'>
        <Breadcrumbs title="Newjo" breadcrumbItem='Cover types' />
        <div className="card">
        <div className='addButton'>
            <Button  color='success' onClick={toggle}>+ New cover type</Button>
          </div>
        <div className="table-responsive">
                <Table striped bordered hover>

                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Cover type</th>
                            <th scope="col">Description</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        {covertypes.map((val,index)=>{
                        
                            return(
                                <tr>
                                    <td>{index+1}</td>
                                 
                                    <td><button  className='tableButton' onClick={toggle2} value={val.id} >{val.CoverTypeDescription}</button></td>
                                    <td>{val.CoverTypeCode}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </div>

        <Modal isOpen = {modal} toggle={toggle}>
        <ModalHeader>Add new cover type</ModalHeader>
        <div className="modalContainer">
            <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Cover type name" onChange={(e)=>{setCovertype(e.target.value)}}>
                <Form.Control  className='inputSearch' value={coverType}  type="text" placeholder="Cover type name" />
            </FloatingLabel>

            <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Cover type description" onChange={(e)=>{setCoverdescription(e.target.value)}}>
                <Form.Control  className='inputSearch' value={coverDescription}  type="text" placeholder="Cover type description" />
            </FloatingLabel>

            <Button color='success' style={{marginTop:'3%'}} onClick={submitCover}>Add cover</Button>

        </div>

        </Modal>

        <Modal isOpen = {modal} toggle={toggle}>
        <ModalHeader>Edit cover type</ModalHeader>
        <div className="modalContainer">
            <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Cover type name" onChange={(e)=>{setCovertype(e.target.value)}}>
                <Form.Control  className='inputSearch' value={coverType}  type="text" placeholder="Cover type name" />
            </FloatingLabel>

            <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Cover type description" onChange={(e)=>{setCoverdescription(e.target.value)}}>
                <Form.Control  className='inputSearch' value={coverDescription}  type="text" placeholder="Cover type description" />
            </FloatingLabel>

            <div className="modalButtons">
                <Button style={{marginTop:'3%'}} color='success' onClick={updateCover} >Save changes</Button>

                <Button style={{marginTop:'3%',marginLeft:'2%'}}  color='danger' onClick={deleteCover} >Remove cover type</Button>
            </div>

        </div>

        </Modal>
    </div>
  )
}
