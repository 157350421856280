import React from 'react';
import { useState,useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import Table from 'react-bootstrap/Table';

import { LatestTransationData } from '../../CommonData/Data/index';

const LatestTransation = () => {
    const [policies, setPolicies] = useState([])

    useEffect(()=>{
        const fetchData = async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getPoliciesexpiringsoon');
            const data = await res.json();
            setPolicies(data)
        }

        fetchData()

    },[])


    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Policies expiring soon</h4>

                            <div className="table-responsive">
                            <Table striped bordered hover>

                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Policy Number</th>
                                        <th scope="col">Customer</th>
                                        <th scope="col">Insurer</th>
                                        <th scope="col">Expiry date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {policies.map((val,index)=>{
                                        const expiryDate = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.expiryDate)
                                        return(
                                            <tr>
                                                <td>{index+1}</td>
                                                <td><button className='tableButton'>{val.policyNumber}</button></td>
                                                <td>{val.client}</td>
                                                <td>{val.insurer}</td>
                                                <td>{expiryDate}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default LatestTransation;