import {React} from "react";
import { useEffect,useState } from "react";
import ReactApexChart from "react-apexcharts";




const LineColumnArea = () => {

  const [newPolicies, setNewpolicies] = useState([])
  const [expired, setExpired] = useState([])
  const [renewed, setRenewed] = useState([])

  useEffect(()=>{
    const fetchNewpolicies =async()=>{
      const res = await fetch('https://panel.yunshuglobal.com:3060/api/policies-per-month');
      const data = await res.json();
      setNewpolicies(data)
    }
    const fetchExpiredpolicies =async()=>{
      const res = await fetch('https://panel.yunshuglobal.com:3060/api/expiry-per-month');
      const data = await res.json();
      setExpired(data)
    }

    const fetchRenewedpolicies =async()=>{
      const res = await fetch('https://panel.yunshuglobal.com:3060/api/renewal-per-month');
      const data = await res.json();
      setRenewed(data)
    }

    fetchNewpolicies();
    fetchExpiredpolicies();
    fetchRenewedpolicies();

  },[])


  const LineColumnAreaData = {

  



    series: [
      {
        name: "New policies",
        type: "column",
        data: newPolicies,
      },
      {
        name: "Expired policies",
        type: "area",
        data: expired,
      },
      {
        name: "Renewed",
        type: "line",
        data: renewed,
      },
    ],
    options: {
      chart: {
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 0.5, 1],
        curve: "smooth",
        dashArray: [0, 8, 5]
      },
      plotOptions: {
        bar: {
          columnWidth: "18%",
        },
      },
      colors: ["#0ab39c", "rgba(212, 218, 221, 0.18)", "rgb(251, 77, 83)"],
  
      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      markers: {
        size: 0,
      },
      legend: {
        offsetY: 11,
      },
      xaxis: {
        type: "month",
      },
      yaxis: {
        title: {
          text: "Policies",
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + " policies"
            }
            return y
          },
        },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  }


  return(
    <div>
        <ReactApexChart
          options={LineColumnAreaData.options}
          series={LineColumnAreaData.series}
          type="line"
          height="350"
          stacked= "false"
          className="apex-charts"
        />
      </div>
  )
}

export default LineColumnArea;