import React from "react";
import { useState, useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import RadialChart1 from "./userpanelChart1";
import RadialChart2 from "./userpanelChart2";
import RadialChart3 from "./userpanelChart3";
import { useNavigate } from "react-router-dom";

const UserPanel = () => {
  const [clients, setClients] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [claims, setClaims] = useState([]);
  const [commission, setCommission] = useState('');
  const [premium, setPremium] = useState('')
  const [products, setProducts] = useState([])
  const navigate = useNavigate()

  useEffect(()=>{

    const fetchClients =async()=>{
      const res = await fetch('https://panel.yunshuglobal.com:3060/api/getClients')
      const data = await res.json();

      setClients(data)
    }
    const fetchPolicies =async()=>{
      const res = await fetch('https://panel.yunshuglobal.com:3060/api/getPolicies')
      const data = await res.json();

      setPolicies(data)
    }

    const fetchClaims =async()=>{
      const res = await fetch('https://panel.yunshuglobal.com:3060/api/getClaims')
      const data = await res.json();

      setClaims(data)
    }

    const fetchPremium =async()=>{
      const res = await fetch('https://panel.yunshuglobal.com:3060/api/getPremium')
      const data = await res.json();

      setPremium(data.premium)
    }
    const fetchCommision =async()=>{
      const res = await fetch('https://panel.yunshuglobal.com:3060/api/getCommission')
      const data = await res.json();

      setCommission(data.commission)
    }

    const fetchProducts =async()=>{
      const res = await fetch('https://panel.yunshuglobal.com:3060/api/getProducts')
      const data = await res.json();

      setProducts(data)
    }


    fetchClients();
    fetchPolicies();
    fetchClaims();
    fetchPremium();
    fetchCommision();
    fetchProducts();


  },[])

  const toClients =()=>{
    navigate('/clients')
  }

  const toPolicies =()=>{
    navigate('/active-policies')
  }

  const toProducts =()=>{
    navigate('/products')
  }

  const toClaims =()=>{
    navigate('/claims')
  }

  const toPremiums =()=>{
    navigate('/premiums')
  }
  const toCommissions =()=>{
    navigate('/commission')
  }

  return (
    <React.Fragment>
      <Row>
        <Col xl={2} sm={6} className='tbs' onClick={toClients}>
          <Card style={{backgroundColor:'#32a852'}} className='dashboardCards'>
            <CardBody>
              <div className="d-flex text-muted">
            
                <div className="flex-grow-1 overflow-hidden">
                  <p style={{color:'#fff'}} className="mb-1">All clients</p>
                  <h5 style={{color:'#fff'}} className="mb-3">{clients.length}</h5>
                 
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={2} sm={6} className='tbs' onClick={toPolicies}>
          <Card style={{backgroundColor:'#32a86b'}} className='dashboardCards'>
            <CardBody>
              <div className="d-flex">
               

                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1" style={{color:'#fff'}}>All policies</p>
                  <h5 className="mb-3" style={{color:'#fff'}}>{policies.length}</h5>
                  
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={2} sm={6} className='tbs' onClick={toProducts}>
          <Card style={{backgroundColor:'#0309a6'}} className='dashboardCards'>
            <CardBody>
              <div className="d-flex">
               

                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1" style={{color:'#fff'}}>Products</p>
                  <h5 className="mb-3" style={{color:'#fff'}}>{products.length}</h5>
                  
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={2} sm={6} className='tbs' onClick={toClaims}>
          <Card style={{backgroundColor:'#a8326d'}} className='dashboardCards'>
            <CardBody>
              <div className="d-flex text-muted">
              

                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1" style={{color:'#fff'}}>Claims</p>
                  <h5 className="mb-3" style={{color:'#fff'}}>{claims.length}</h5>
                  
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={2} sm={6} className='tbs' onClick={toPremiums}>
          <Card style={{backgroundColor:'#038ea1'}} className='dashboardCards'>
            <CardBody>
              <div className="d-flex text-muted">
               
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1" style={{color:'#fff'}}>Premiums paid</p>
                  <h5 className="mb-3" style={{color:'#fff'}}>Kes. {new Intl.NumberFormat('en-US').format(premium)}</h5>
                 
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={2} sm={6} className='tbs' onClick={toCommissions}>
          <Card style={{backgroundColor:'#06bcd4'}} className='dashboardCards'>
            <CardBody>
              <div className="d-flex text-muted">
               
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1" style={{color:'#fff'}}>Earned</p>
                  <h5 className="mb-3" style={{ color: '#fff' }}>
                    Kes. {new Intl.NumberFormat('en-US').format(commission)}
                  </h5>
                 
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserPanel;
