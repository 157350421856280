import {React, useState, useEffect} from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Table from 'react-bootstrap/Table';
import { Button,Modal,ModalHeader } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

export default function Rights() {
    document.title = "Settings-Rights | Newjo Insurance Agency";

    const [rights, setRights] = useState([]);
    const [role, setRole] = useState('');
    const [addClient, setAddclient] = useState('');
    const [editClient, setEditclient] = useState('');
    const [addPolicy, setAddpolicy] = useState('');
    const [editPolicy, setEditPolicy] = useState('');
    const [reports, setReports] = useState('');
    const [addStaff, setAddstaff] = useState('');
    const [editStaff, setEditstaff] = useState('');
    const [addCompanies, setAddcompanies] = useState('');
    const [editCompanies, setEditcompanies] = useState('');
    const [sendCommunication, setSendcommunication] = useState('');
    const [addProducts, setAddproducts] = useState('')
    const [editProducts, setEditproducts] = useState('');
    const [selectedRight, setSelectedright] = useState("")
    const [modal, setModal] = useState(false)
    const [modal2, setModal2] = useState(false)
    const [submitText, setSubmittext] = useState('Submit')
    const [updateText, setUpdatetext] = useState('Save changes')
    const [deleteText, setDeletetext] = useState('Remove')

    useEffect(()=>{
        const fetchRights =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getRights');
            const data = await res.json();
            setRights(data)

        }

        
        fetchRights();
        

    },[])

    const fetchRights =async()=>{
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/getRights');
        const data = await res.json();
        setRights(data)

    }

    const toggle =()=>{
        setModal(!modal)
    }

    const submitRight =async()=>{
        
            setSubmittext('Please wait...')
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/submitRights',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({role,addClient,editClient,addPolicy,editPolicy,reports,addStaff,editStaff,addCompanies,editCompanies,sendCommunication,addProducts,editProducts}),
            credentials:"include"
        })

        const data = await res.json();
            const {success} = data;
            if(success == true){
                setSubmittext('Submit')
                toast.success('Data added');
                fetchRights();
                setModal(false)
            }
            else{
                setSubmittext('Submit')
                toast.error('Something went wrong!')
            }

        }

        const toggle2 =async(e)=>{
            const id = e.target.value;
            setSelectedright(id)
            const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getThisRight?id=${id}`);
            const data = await res.json();
            setRole(data.role)
            setAddclient(data.add_client)
            setEditclient(data.edit_client)
            setAddpolicy(data.add_policy);
            setEditPolicy(data.edit_policy);
            setAddstaff(data.add_staff);
            setEditstaff(data.edit_staff);
            setAddcompanies(data.add_companies);
            setEditcompanies(data.edit_companies);
            setAddproducts(data.add_products)
            setEditproducts(data.edit_products)
            setReports(data.view_reports);
            setSendcommunication(data.send_communication)
            
    
            setModal2(!modal2)
    
        }
    
        const updateRight =async()=>{
            setUpdatetext('Please wait...')
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/updateRight',{
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({role,addClient,editClient,addPolicy,editPolicy,reports,addStaff,editStaff,addCompanies,editCompanies,sendCommunication,addProducts,editProducts,selectedRight}),
                credentials:"include"
            })
    
            const data = await res.json();
                const {success} = data;
                if(success == true){
                    setUpdatetext('Save changes')
                    toast.success('Changes saved');
                    fetchRights();
                    setModal2(false)
                }
                else{
                    setUpdatetext('Save changes')
                    toast.error('Something went wrong!')
                }
        }
    
    
        const removeRight =async()=>{
            setDeletetext('Please wait')
            const res = await fetch(`https://panel.yunshuglobal.com:3060/api/deleteRight?id=${selectedRight}`);
            const data = await res.json();
            const {success} = data;
            if(success == true){
                    setDeletetext('Remove')
                    toast.success('Role removed');
                    fetchRights();
                    setModal2(false)
                }
                else{
                    setDeletetext('Remove')
                    toast.error('Something went wrong!')
                }
    
        }
       
    




  return (
    <div className='pagesContainer'>
         <ToastContainer />
          <Breadcrumbs title="Newjo" breadcrumbItem='Rights & Roles' />
          <div className="card">
          <div className='addButton'>
            <Button  color='success' onClick={toggle} >+ New Role</Button>
            </div>


            <div className="table-responsive">
                <Table striped bordered hover>

                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Role</th>
                            <th scope="col">Add client</th>
                            <th scope="col">Edit client</th>
                            <th scope="col">Add policy</th>
                            <th scope="col">Edit Policy</th>
                            <th scope="col">Add staff</th>
                            <th scope="col">Edit staff</th>
                            <th scope="col">Add companies</th>
                            <th scope="col">Edit companies</th>
                            <th scope="col">Add products</th>
                            <th scope="col">Edit products</th>
                            <th scope="col">View reports</th>
                            <th scope="col">Send communication</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {rights.map((val,index)=>{
                           
                            return(
                            <tr>
                                <td>{index+1}</td>       
                                <td><button className='tableButton' value={val.id} onClick={toggle2}  >{val.role}</button></td>
                                {val.add_client == 'YES' &&
                                    <td> <DoneIcon color='success' /></td>
                                }
                                {val.add_client == 'NO' &&
                                    <td> <CloseIcon style={{color:'red'}} /></td>
                                }

                                {val.edit_client == 'YES' &&
                                    <td> <DoneIcon color='success' /></td>
                                }
                                {val.edit_client == 'NO' &&
                                    <td> <CloseIcon style={{color:'red'}} /></td>
                                }


                                {val.add_policy == 'YES' &&
                                    <td> <DoneIcon color='success' /></td>
                                }
                                {val.add_policy == 'NO' &&
                                    <td> <CloseIcon style={{color:'red'}} /></td>
                                }

                                {val.edit_policy == 'YES' &&
                                    <td> <DoneIcon color='success' /></td>
                                }
                                {val.edit_policy == 'NO' &&
                                    <td> <CloseIcon style={{color:'red'}} /></td>
                                }

                                {val.add_staff == 'YES' &&
                                    <td> <DoneIcon color='success' /></td>
                                }
                                {val.add_staff == 'NO' &&
                                    <td> <CloseIcon style={{color:'red'}} /></td>
                                }

                                {val.edit_staff == 'YES' &&
                                    <td> <DoneIcon color='success' /></td>
                                }
                                {val.edit_staff == 'NO' &&
                                    <td> <CloseIcon style={{color:'red'}} /></td>
                                }

                                {val.add_companies == 'YES' &&
                                    <td> <DoneIcon color='success' /></td>
                                }
                                {val.add_companies == 'NO' &&
                                    <td> <CloseIcon style={{color:'red'}} /></td>
                                }

                                {val.edit_companies == 'YES' &&
                                    <td> <DoneIcon color='success' /></td>
                                }
                                {val.edit_companies == 'NO' &&
                                    <td> <CloseIcon style={{color:'red'}} /></td>
                                }

                                {val.add_products == 'YES' &&
                                    <td> <DoneIcon color='success' /></td>
                                }
                                {val.add_products == 'NO' &&
                                    <td> <CloseIcon style={{color:'red'}} /></td>
                                }

                                {val.edit_products == 'YES' &&
                                    <td> <DoneIcon color='success' /></td>
                                }
                                {val.edit_products == 'NO' &&
                                    <td> <CloseIcon style={{color:'red'}} /></td>
                                }
                                {val.view_reports == 'YES' &&
                                    <td> <DoneIcon color='success' /></td>
                                }
                                {val.view_reports == 'NO' &&
                                    <td> <CloseIcon style={{color:'red'}} /></td>
                                }

                                {val.send_communication == 'YES' &&
                                    <td> <DoneIcon color='success' /></td>
                                }
                                {val.send_communication == 'NO' &&
                                    <td> <CloseIcon style={{color:'red'}} /></td>
                                }
                           
                            </tr>
                            )

                            
                           
                            


                            
                        })}
                    </tbody>
                </Table>
            </div>
          </div>

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader>New role</ModalHeader>
            <div className="modalContainer">
            <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Role" onChange={(e)=>{setRole(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={role}  type="text" placeholder="Role" />
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="ADD CLIENT" onChange={(e)=>{setAddclient(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select option</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="EDIT CLIENT" onChange={(e)=>{setEditclient(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select option</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="ADD POLICY" onChange={(e)=>{setAddpolicy(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select option</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="EDIT POLICY" onChange={(e)=>{setEditPolicy(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select option</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="ADD STAFF" onChange={(e)=>{setAddstaff(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select option</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="EDIT STAFF" onChange={(e)=>{setEditstaff(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select option</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="ADD COMPANY" onChange={(e)=>{setAddcompanies(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select option</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="EDIT COMPANIES" onChange={(e)=>{setEditcompanies(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select option</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="ADD PRODUCTS" onChange={(e)=>{setAddproducts(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select option</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="EDIT PRODUCTS" onChange={(e)=>{setEditproducts(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select option</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="SEND COMMUNICATION" onChange={(e)=>{setSendcommunication(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select option</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="VIEW REPORTS" onChange={(e)=>{setReports(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select option</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
   
                <Button color='success' style={{marginTop:'3%'}} onClick={submitRight}>{submitText}</Button>
            </div>
        </Modal>



        <Modal isOpen={modal2} toggle={toggle2}>
            <ModalHeader>Edit</ModalHeader>
            <div className="modalContainer">
            <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Role" onChange={(e)=>{setRole(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={role}  type="text" placeholder="Role" />
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="ADD CLIENT" onChange={(e)=>{setAddclient(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>{addClient}</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="EDIT CLIENT" onChange={(e)=>{setEditclient(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>{editClient}</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="ADD POLICY" onChange={(e)=>{setAddpolicy(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>{addPolicy}</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="EDIT POLICY" onChange={(e)=>{setEditPolicy(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>{editPolicy}</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="ADD STAFF" onChange={(e)=>{setAddstaff(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>{addStaff}</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="EDIT STAFF" onChange={(e)=>{setEditstaff(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>{editStaff}</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="ADD COMPANY" onChange={(e)=>{setAddcompanies(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>{addCompanies}</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="EDIT COMPANIES" onChange={(e)=>{setEditcompanies(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>{editCompanies}</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="ADD PRODUCTS" onChange={(e)=>{setAddproducts(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>{addProducts}</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="EDIT PRODUCTS" onChange={(e)=>{setEditproducts(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>{editProducts}</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="SEND COMMUNICATION" onChange={(e)=>{setSendcommunication(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>{sendCommunication}</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="VIEW REPORTS" onChange={(e)=>{setReports(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>{reports}</option>
                        <option value='NO'>NO</option>
                        <option value='YES'>YES</option>


                    </Form.Select>
                </FloatingLabel>
   
                <div className="modalButtons">
                <Button color='success' style={{marginTop:'3%'}} onClick={updateRight}>{updateText}</Button>
                <Button color='danger' style={{marginTop:'3%',marginLeft:"3%"}} onClick={removeRight}>{deleteText}</Button>
                <Button color='danger' style={{marginTop:'3%',marginLeft:"3%"}} onClick={()=>setModal2(false)}>Close</Button>

                </div>
            </div>
        </Modal>
    </div>
  )
}
