import {React,useState,useEffect} from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Table from 'react-bootstrap/Table';
import { Button,Modal,ModalHeader } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Axios from 'axios'

export default function Pendingpolicies() {
    document.title = "New policies | Newjo Insurance Agency";

    const [policies, setPolicies] = useState([]);
    const [duration, setDuration] = useState('annually');
    const [expiryDate, setExpirydate] = useState(null);
    const [modal, setModal] = useState(false);
    const [selectedPolicy, setSelectedpolicy] = useState('')
    const [policyNumber, setPolicynumber] = useState('')
    const [startDate, setStartdate] = useState('')
    const [approveText, setApprovetext] = useState('Approve Policy')
    const [rejectText, setRejecttext] = useState('Reject Policy')
    const [tab, setTab] = useState(1);
    const [riskClasses, setRiskclasses] = useState([])
    const [covers, setCovers] = useState([]);
    const [products, setProducts] = useState([]);
    const [clients, setClients] = useState([]);
    const [clientID, setClientID] = useState('');
    const [clientPhone, setClientphone] = useState("")
    const [clientEmail, setClientemail] = useState('')
    const [clientPin, setClientpin] = useState('')
    const [Identification, setIdentification] = useState('')
    const [sumInsured, setSumInsured] = useState(0); // Base sum insured
    const [benefits, setBenefits] = useState([]);
    const [selectedBenefits, setSelectedBenefits] = useState([]);
    const [selectedRisk, setSelectedrisk] = useState('');
    const [grossPremium, setGrossPremium] = useState(0);
    const [selectedCover, setSelectedcover] = useState('');
    const [selectedProduct, setSelectedproduct] = useState('');
    const [clientName, setClientname] = useState('')
    const [minimum, setMinimum] = useState(0);
    const [rate, setRate] = useState(0)
    const [minimumPremium,setMinimumpremium] = useState(0)
    const [companies, setCompanies] = useState([])
    const [insurer, setInsurer] = useState('')
    const [commission, setCommission] = useState(0)
    const [insureItem, setInsureitem] = useState('')
    const [modal2, setModal2] = useState(false)
    const [insurerEmail, setInsureremail] = useState('')
    const [submitshareText, setSubmitsharetext] = useState('Save with insurer')
    const [submitText, setSubmittext] = useState('Save locally')
    const [modal3, setModal3] = useState(false)
    const [modal4, setModal4] = useState(false);
    const [modal5, setModal5] = useState(false);
    const [modal6, setModal6] = useState(false);
    const [terminateText, setTerminatetext] = useState('Confirm termination')
    const [file, setFile] = useState(null);
    const [activePolicies, setActivepolicies] = useState([]);
    const [uploadText, setUploadtext] = useState('Import data')

    const handleFileUpload = async (e) => {
    e.preventDefault();

    if (!file) {
      alert("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
        setUploadtext('Please wait...')
      const response = await fetch("https://panel.yunshuglobal.com:3060/api/importPolicies", {
        method: "POST",
        body: formData,
      });

      const result = await response.json();
      if (response.ok) {
        fetchPolicies()
        setModal6(false)
        toast.success('Policies imported successfully')
        setUploadtext('Import data')
      } else {
        toast.error('Something went wrong')
        setUploadtext('Import data')
      }
    } catch (err) {
        toast.error('Something went wrong')
        setUploadtext('Import data')
    }
  };

    





    useEffect(()=>{

        const getClient = async()=>{
           const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getClient?id=${Identification}`);
           const data = await res.json();
           setClientemail(data.email)
           setClientphone(data.phone)
        }

        if(Identification){
            getClient()
        }
        

    },[Identification])
  

    

    const toggle5 =async()=>{
        setModal5(!modal5)
    }

    const toggle3 =async(e)=>{
        const id = e.target.value;
        setSelectedpolicy(id)
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getPolicy?policy=${id}`);
        
        const data = await res.json();
        setClientname(data.client)
        setPolicynumber(data.policyNumber);
        setInsurer(data.insurer)
        setSelectedrisk(data.policyClass);
        setSelectedproduct(data.product)
        setIdentification(data.identification)
        setInsureitem(data.registrationNo)
        setSumInsured(data.sumInsured)
        setGrossPremium(data.grossPremium);
        setCommission(data.commission);
        setExpirydate(new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(data.expiryDate))
        setStartdate(new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(data.date))


        setModal3(!modal3)
    }

    const  toggle2 =async()=>{
        if(commission !== 0){ 
            setModal2(!modal2)
          }
        else{
            toast.error('Please set commission first')
        }
        
    }

    const toggle4 =async()=>{
        setModal4(!modal4)
    }

    

    const terminatePolicy =async()=>{
        setTerminatetext('Please wait')
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/terminatePolicy',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({id:selectedPolicy}),
            credentials:"include"
        });
        
        const data = await res.json();
        const {success} = data;

        if(success == true){
            setTerminatetext('Confirm termination')
            toast.success('Policy has been terminated')
            setModal4(false)
            setModal3(false)
            fetchPolicies()
        }
        else{
            setTerminatetext('Confirm termination') 
            toast.error('Something went wrong!')
        }

    }
    

    useEffect(()=>{

        const fetchPolicies =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/fetchActivepolicies');
            const data = await res.json();
            setActivepolicies(data)
        }

        const fetchRiskclasses =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getRiskclasses');
            const data = await res.json();
            setRiskclasses(data)
        }

        const fetchCovertypes =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getCovertypes');
            const data = await res.json();
            setCovers(data)
        }
        const fetchProducts =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getProducts');
            const data = await res.json();
            setProducts(data)
        }

        const fetchClients = async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getClients');
            const data = await res.json();
            setClients(data)
        }
        const fetchCompanies = async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getCompanies');
            const data = await res.json();
            setCompanies(data)
        }

        

        fetchClients();
        fetchPolicies();
        fetchRiskclasses();
        fetchCovertypes();
        fetchProducts();
        fetchCompanies();
       


    },[])

    useEffect(()=>{
        const fetchdata =async()=>{
            const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getThisclient?id=${clientID}`);
            const data = await res.json();
        
            setClientphone(data.phone);
            setClientID(data.idNumber);
            setClientpin(data.pinNumber);
            setClientemail(data.email);
            setIdentification(data.idNumber)
            setClientname(`${data.fname} ${data.lname} ${data.otherName} `)
        
        }
        if(clientID){
            fetchdata();
        }

    },[clientID])

    const tab1 =async()=>{
        setTab(1)
    }
    const tab2 =async()=>{
        setTab(2)
    }
    const tab3 =async()=>{
        setTab(3)
    }


    useEffect(()=>{
        const fetchBenefits = async()=>{
            const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getBenefits?risk=${selectedRisk}`);
            const data = await res.json();
            setBenefits(data)
        }

        if(selectedRisk){
            fetchBenefits();
        }

    },[selectedRisk])

    const handleCheckboxChange = (benefitId) => {
        setSelectedBenefits((prevSelected) => {
          if (prevSelected.includes(benefitId)) {
            // If already selected, remove it
            return prevSelected.filter((id) => id !== benefitId);
          } else {
            // Add the newly selected benefit
            return [...prevSelected, benefitId];
          }
        });
      };


      // Calculate grossPremium whenever sumInsured or selectedBenefits change
  useEffect(() => {
    let total = 0;
    
    if(parseFloat(sumInsured) < parseFloat(minimum)){
        total += parseFloat(minimumPremium)
 
    }
    if(parseFloat(sumInsured) > parseFloat(minimum)){
        total += (rate/100)*parseFloat(sumInsured)
    }

    selectedBenefits.forEach((benefitId) => {
      const benefit = benefits.find((b) => b.benefit === benefitId);
      
      if (benefit) {
        if (benefit.calculation === 'amount') {
          total += parseFloat(benefit.amount || 0);
        } else if (benefit.calculation === 'percentage') {
          total += (parseFloat(benefit.amount || 0) / 100) * parseFloat(sumInsured || 0);
        }
      }
    });

    setGrossPremium(total); // Update gross premium
  }, [minimum,rate,minimumPremium,sumInsured, selectedBenefits, benefits]);


  const submitPolicy =async()=>{
        setSubmittext('Please wait...')
        const standardCommission = (commission)/100*(parseFloat(grossPremium))

        const res = await fetch('https://panel.yunshuglobal.com:3060/api/submitPolicy',{
            method:'POST',
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                clientName,clientPhone,clientEmail,clientPin,Identification,insurer,selectedRisk,selectedProduct,insureItem,sumInsured,commission,grossPremium,standardCommission
            }),
            credentials:'include'
        })

        const data = await res.json();
        const {success} = data;
        if(success == true){
            toast.success('Policy saved! Proceed to pending proposals to activate')
            setSubmittext('Save locally')
            setModal(false)
            setModal2(false)
        }
        else{
            toast.error('Something went wrong')
            setSubmittext('Save locally')
        }



  }

  const submitPolicyEmail =async()=>{
   setSubmitsharetext('Please wait...')
    const standardCommission = (commission)/100*(parseFloat(grossPremium))

    const message =`Greeting ${insurer}. \n Please receive the following details for a new insurance policy. Insured: ${clientName} \n Email: ${clientEmail} \n Phone: ${clientPhone} \n Risk class: ${selectedRisk} \n Cover type: ${selectedCover} \n Product: ${selectedProduct} \n Insured Item: ${insureItem} \n Sum insured: ${sumInsured}`

    const res = await fetch('https://panel.yunshuglobal.com:3060/api/submitPolicy',{
        method:'POST',
        headers:{
            "Content-Type":"application/json"
        },
        body:JSON.stringify({
            clientName,clientPhone,clientEmail,clientPin,Identification,insurer,selectedRisk,selectedProduct,insureItem,sumInsured,commission,grossPremium,standardCommission
        }),
        credentials:'include'
    })

    const data = await res.json();
    const {success} = data;
    if(success == true){
        const sendDetails = await Axios.post('https://n8n.uidevlabs.com/webhook/7127918e-4dc3-42ed-9084-4ce19a94c162',{insurerEmail,message})
        toast.success('Policy saved! Proceed to pending proposals to activate')
        setSubmitsharetext('Save with insurer')
        setModal(false)
        setModal2(false)
    }
    else{
        toast.error('Something went wrong')
        setSubmitsharetext('Save with insurer')
    }



}

    const toggle =async(e)=>{
        const id = e.target.value;
        setSelectedpolicy(id)
        setModal(!modal)

    }


    const fetchPolicies =async()=>{
        const res= await fetch('https://panel.yunshuglobal.com:3060/api/getPendingpolicies');
        const data = await res.json();
        setPolicies(data)

    }

    useEffect(()=>{

        const fetchPolicies =async()=>{
            const res= await fetch('https://panel.yunshuglobal.com:3060/api/getPendingpolicies');
            const data = await res.json();
            setPolicies(data)

        }

        fetchPolicies()

    },[])

    useEffect(()=>{
        const calculateExpiryDate = () => {
            const now = Date.now(); // Current time in milliseconds since epoch
            setStartdate(now)

            let calculatedExpiry;
        
            if (duration === 'monthly') {
              // Add 1 month (30 days) in milliseconds
              calculatedExpiry = now + 30 * 24 * 60 * 60 * 1000;
            } else if (duration === 'annually') {
              // Add 1 year (365 days) in milliseconds
              calculatedExpiry = now + 365 * 24 * 60 * 60 * 1000;
            }
        
            setExpirydate(calculatedExpiry);
          };

          if(duration){
            calculateExpiryDate()
          }

    },[duration])

    const approve =async()=>{
        if(policyNumber !== ''){
            setApprovetext('Please wait');
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/approvePolicy',{
                method:'POST',
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({selectedPolicy,expiryDate,startDate,policyNumber}),
                credentials:"include"

            });

            const data = await res.json();
            const {success} = data;
            if(success == true){
                fetchPolicies()
                toast.success('Policy has been approved');
                setModal(false)
                setApprovetext('Approve policy')
            }
            else{
                
                setApprovetext('Approve policy')
                toast.error('Something went wrong')
            }

            

        }
        else{
            toast.error('Please specify policy number')
        }
    }

    const reject =async()=>{
        setRejecttext('Please wait');
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/rejectPolicy',{
            method:'POST',
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({selectedPolicy}),
            credentials:"include"

        });

        const data = await res.json();
        const {success} = data;
        if(success == true){
            fetchPolicies()
            toast.success('Policy has been rejected');
            
            setRejecttext('Reject policy')
            setModal(false)
        }
        else{
            setRejecttext('Reject policy')
            toast.error('Something went wrong')
        }


    }

    const toggle6 =()=>{
        setModal6(!modal6)
    }
  return (
    <div className='pagesContainer'>
    <ToastContainer />
        <Breadcrumbs title="Newjo" breadcrumbItem='Pending policies' />
        <div className="card">
        <div className='addButton'>
            <Button onClick={toggle5} color='success' >+ New policy</Button>
            <Button onClick={toggle6} >+Import</Button>
          </div>
          
        <div className="table-responsive">
                <Table striped bordered hover>

                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Date</th>
                            <th scope="col">Policy Number</th>
                            <th scope="col">Client</th>
                            <th scope="col">Insurer</th>
                            <th scope="col">Product</th>
                            <th scope="col">Insured Item</th>
                            <th scope="col">Expiry</th>
                        </tr>
                    </thead>
                    <tbody>
                        {policies.map((val,index)=>{
                            const policyDate = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.date)
                            const expiryDate = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.expiryDate)
                            return(
                                <tr>
                                    <td>{index+1}</td>
                                    <td>{policyDate}</td>
                                    <td><button onClick={toggle} className='tableButton' value={val.id} >{val.policyNumber}</button></td>
                                    <td>{val.client}</td>
                                    <td>{val.insurer}</td>
                                    <td>{val.product}</td>
                                    <td>{val.registrationNo}</td>
                                    <td>{expiryDate}</td>
                                    

                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </div>

        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader>Policy approval</ModalHeader>
            <div className="modalContainer">
            <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Policy number" onChange={(e)=>{setPolicynumber(e.target.value)}}>
                <Form.Control  className='inputSearch' value={policyNumber}  type="text" placeholder="Policy number" />
            </FloatingLabel>
            <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Duration" onChange={(e)=>{setDuration(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Duration</option>
                        <option value="monthly">1 Monthly</option>
                        <option value="annually">Annual</option>
                    </Form.Select>
                </FloatingLabel>

                {expiryDate && (
                    <div>
          
                        <p>Expiry Date: {new Date(expiryDate).toLocaleString()}</p>
                </div>
                )}
            <div className="modalButtons">
                <Button style={{marginTop:'3%'}} color='danger' onClick={reject}>{rejectText}</Button>

                <Button style={{marginTop:'3%',marginLeft:'2%'}}  color='success' onClick={approve} >{approveText}</Button>
                </div>
            </div>
        </Modal>


        <Modal isOpen={modal5} toggle={toggle5} size='md'>
        <ModalHeader>New Policy</ModalHeader>

        <div className="modalContainer">
        {tab == 1 &&
        <>
            <h5>Client information</h5>
            <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Select client" onChange={(e)=>{setClientID(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Clients</option>
                        {clients.map((val)=>{
                            return(
                                <option value={val.id}>{val.fname} {val.lname} {val.otherName}</option>
                            )
                            
                        })}
           
                    </Form.Select>
             </FloatingLabel>
             <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Identification number">
                <Form.Control  className='inputSearch' value={Identification}  type="text" placeholder="Identification number" disabled />
            </FloatingLabel>
            <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Phone number">
                <Form.Control  className='inputSearch' value={clientPhone}  type="text" placeholder="Phone number" disabled />
            </FloatingLabel>
            <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Email address">
                <Form.Control  className='inputSearch' value={clientEmail}  type="text" placeholder="Email address" disabled />
            </FloatingLabel>
            <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="KRA Pin">
                <Form.Control  className='inputSearch' value={clientPin}  type="text" placeholder="KRA Pin" disabled />
            </FloatingLabel>

            <Button style={{marginTop:'3%'}} onClick={tab2} color='success'>Next step</Button>

        </>   
        
        }
        {tab == 2 &&
        
            <>
                <h5>Policy information</h5>

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Insurer" onChange={(e)=>{setInsurer(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Insurer</option>
                        {companies.map((val)=>{
                            return(
                                <option value={val.companyName}>{val.companyName}</option>
                            )
                            
                        })}
           
                    </Form.Select>
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Risk class" onChange={(e)=>{setSelectedrisk(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Risk class</option>
                        {riskClasses.map((val)=>{
                            return(
                                <option value={val.RiskCode}>{val.RiskClass}</option>
                            )
                            
                        })}
           
                    </Form.Select>
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Cover types" onChange={(e)=>{setSelectedcover(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Cover type</option>
                        {covers.map((val)=>{
                            return(
                                <option value={val.CoverTypeCode}>{val.CoverTypeDescription}</option>
                            )
                            
                        })}
           
                    </Form.Select>
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Products" onChange={(e)=>{setSelectedproduct(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Products</option>
                        {products.map((val)=>{
                            return(
                                <option value={val.ProductDescription}>{val.ProductDescription}</option>
                            )
                            
                        })}
           
                    </Form.Select>
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Insured item" onChange={(e)=>{setInsureitem(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={insureItem}  type="text" placeholder="Insured item" />
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="% rate" onChange={(e)=>{setRate(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={rate}  type="number" placeholder="% rate" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Minimum sum insured" onChange={(e)=>{setMinimum(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={minimum}  type="text" placeholder="Minimum sum insured" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Minimum premium" onChange={(e)=>{setMinimumpremium(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={minimumPremium}  type="text" placeholder="Minimum premium" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Sum insured" onChange={(e)=>{setSumInsured(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={sumInsured}  type="text" placeholder="Sum insured" />
                </FloatingLabel>
                <p className='additionalBenefits'>Additional benefits</p>
                    {benefits.map((benefit) => (
                        <div key={benefit.benefit}>
                        <input
                            type="checkbox"
                            id={`benefit-${benefit.benefit}`}
                            value={benefit.benefit}
                            onChange={() => handleCheckboxChange(benefit.benefit)}
                        />
                        {benefit.calculation === 'amount' && (
                            <label htmlFor={`benefit-${benefit.benefit}`}>
                            {benefit.benefit} - Kes.{benefit.amount}
                            </label>
                        )}
                        {benefit.calculation === 'percentage' && (
                            <label htmlFor={`benefit-${benefit.benefit}`}>
                            {benefit.benefit} - {benefit.amount}%
                            </label>
                        )}
                        </div>
                    ))}

                    
                    

                <div className="modalButtons">
                <Button style={{marginTop:'3%'}} onClick={tab1} color='danger'>Previous step</Button>

                <Button style={{marginTop:'3%',marginLeft:'2%'}} onClick={tab3} color='success'>Calculate premium</Button>
                </div>
            </>
    
         }

         {tab == 3 &&
            <>
                <h4>Gross Premium: KES: {new Intl.NumberFormat('en-US').format(grossPremium)}</h4>
                <hr />
                <h6>Policy summary</h6>
                <p>Client: {clientName}</p>
                <p>Email: {clientEmail}</p>
                <p>Phone: {clientPhone}</p>
                <p>Identification: {Identification}</p>
                <p>KRA Pin: {clientPin}</p>
                <hr />
                <p>Risk class: {insurer}</p>
                <p>Risk class: {selectedRisk}</p>
                <p>Cover: {selectedProduct}</p>
                <p>Cover type: {selectedCover}</p>
                <p>Risk class: {rate}%</p>
                <p>Insured item: {insureItem}</p>
                <p>Sum insured: Kes {new Intl.NumberFormat('en-US').format(sumInsured)}</p>
                <p>Additional benefits</p>
        
                    <ul>
                    {selectedBenefits.map((id) => {
                        const benefit = benefits.find((b) => b.benefit === id);
                        return <li key={id}>{benefit?.benefit}</li>;
                    })}
                    </ul>
               
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="% Commission" onChange={(e)=>{setCommission(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={commission}  type="number" placeholder=" % Commission" />
                </FloatingLabel>
                <div className="modalButtons">
                <Button style={{marginTop:'3%'}} onClick={tab2} color='danger'>Previous step</Button>

                <Button style={{marginTop:'3%',marginLeft:'2%'}} onClick={toggle2} color='success'>Finish</Button>
                </div>
            </>
         
         
         }

        </div>
        

        </Modal>

        <Modal isOpen={modal2} toggle={toggle2}>
            <ModalHeader>Confirm</ModalHeader>
            <div className="modalContainer">
            <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Insurer email(optional)" onChange={(e)=>{setInsureremail(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={insurerEmail}  type="text" placeholder="Insurer email(optional)" />
            </FloatingLabel>

            <div className="modalButtons">
                <Button style={{marginTop:'3%'}} onClick={submitPolicyEmail} color='info'>{submitshareText}</Button>

                <Button style={{marginTop:'3%',marginLeft:'2%'}} onClick={submitPolicy} color='success'>{submitText}</Button>
                </div>

            </div>
        </Modal>

        <Modal isOpen={modal6} toggle={toggle6}>
            <ModalHeader>Import policies</ModalHeader>
            <div className='modalContainer'>
            <div>
            <p>Import Excel Data</p>
            <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Choose file" onChange={(e) => setFile(e.target.files[0])}>
                    <Form.Control  className='inputSearch' type="file" accept=".xlsx, .xls" />
            </FloatingLabel>

            <Button style={{marginTop:'3%',marginLeft:'2%'}} onClick={handleFileUpload} color='success'>{uploadText}</Button>

            </div>
            </div>
        </Modal>
    </div>
  )
}
