import React from "react";
import { useState, useEffect } from "react";
import RadialChart from "./RadialChart";
import { Button,Modal,ModalHeader,Label,Input } from 'reactstrap';

import { Card, CardBody, Col, Row } from "reactstrap";

import { SocialSourceData } from "../../CommonData/Data/index";

const SocialSource = () => {
  const [dues,setDues] = useState('')
  const [policies, setPolicies] = useState('')
  const[start,setStart] = useState('');
  const[end,setEnd] = useState('');

  useEffect(()=>{

    const fetchDues = async()=>{
      const res = await fetch('https://panel.yunshuglobal.com:3060/api/getMonthdues');
      const data = await res.json();
      setDues(data.totalPremium)
      setPolicies(data.totalPolicies)
    }

    fetchDues();

  },[])

  useEffect(()=>{
    const fetchData = async()=>{
      const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getDuesbyperiod?start=${start}&end=${end}`);
      const data = await res.json();
      setDues(data.totalPremium)
      setPolicies(data.totalPolicies)

    }

    if(start && end){
      fetchData();
    }

  },[start,end])

  const currentDate = new Date();
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Get the current month index and then get the name from the array
  const currentMonth = monthNames[currentDate.getMonth()];


  const handleDateChange = (e) => {
        const dateTime = e.target.value;
        const millisecondsSinceEpoch = new Date(dateTime).getTime();
        setStart(millisecondsSinceEpoch);
    
      };
      const handleDateChange2 = (e) => {
        const dateTime = e.target.value;
        const millisecondsSinceEpoch = new Date(dateTime).getTime();
        setEnd(millisecondsSinceEpoch);
       
      };
  return (
    <React.Fragment>
      <Col xl={4}>
        <Card>
          <CardBody>
            <div className="d-flex  align-items-center">
              <div className="flex-grow-1">
                <h5 className="card-title">{currentMonth}</h5>
              </div>
              
            </div>
            <div className="ssDates">
            <div className="mb-4" id='mb'>
                <Label className="form-label">From date</Label>
                <Input
                    name="text"
                    className="form-control"
                    placeholder="Search order"
                    type="datetime-local"
                    onChange={handleDateChange}
                    // value={email}

                />
                
            </div>
            <div className="mb-4" id='mb'>
                <Label className="form-label">To date</Label>
                <Input
                    name="text"
                    className="form-control"
                    placeholder="Search order"
                    type="datetime-local"
                    onChange={handleDateChange2}
                    // value={email}

                />
                
            </div>
            </div>
            {/* RadialChart */}
            <div className="duesContainer">
              <h5 className="dueTitle">Due for renewal</h5>
              <h3 className="dueText">Kes. {new Intl.NumberFormat('en-US').format(dues)}</h3>
            </div>
            <Row>
             
                <div kclassName="col-4">
                  <div className="social-source text-center mt-3">
                    <div className="avatar-xs mx-auto mb-3">
                      <span
                        className={
                          "avatar-title rounded-circle font-size-18 bg-danger"
                        }
                      >
                        {/* <i className={item.icon" text-white"}></i> */}
                      </span>
                    </div>
                    <h5 className="font-size-15">Policies</h5>
                    <p className="text-muted mb-0">{policies}</p>
                  </div>
                </div>
            
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default SocialSource;
