const SidebarData = [
    {
        label: "Menu",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-home-variant-outline",
        url: "/dashboard",
        issubMenubadge: true,
    },
    {
        label: "Clients",
        icon: "mdi mdi-account-outline",
        isHasArrow: true,
        url: "/clients",
    },
    {
        label: "Policies",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        subItem: [
            { sublabel: "New policies", link: "/pending-policies" },
            { sublabel: "Shared policies", link: "/shared-policies" },
            { sublabel: "Active policies", link: "/active-policies" },
            { sublabel: "Upcoming renewals", link: "/upcoming-renewals" },
            { sublabel: "Expired/Terminated", link: "/expired-policies" },
        ],
    },
    {
        label: "Communication",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/communications",
    },
    {
        label: "Claims",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/claims",
    },
    {
        label: "Reports",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        subItem: [
            { sublabel: "Policies", link: "/report-policies" },
            { sublabel: "Claims", link: "/report-claims" },
            { sublabel: "Finance", link: "/report-finance" },
            
        ],
    },
    {
        label: "Products",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        subItem: [
            
            { sublabel: "Cover types", link: "/cover-types" },
            { sublabel: "Risk classes", link: "/risk-classes" },
            { sublabel: "Products", link: "/products" },
            
        ],
    },
    {
        label: "Companies",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/companies",
    },
    {
        label: "Staff",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/staff",
    },
    {
        label: "Setting",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        subItem: [
            
            { sublabel: "Durations", link: "/settings-durations" },
            { sublabel: "Rights & Roles", link: "/settings-rights" },
            { sublabel: "Message templates", link: "/settings-templates" },
           
            
        ],
    },
]
export default SidebarData;