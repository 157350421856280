import {React, useState, useEffect} from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Table from 'react-bootstrap/Table';
import { Button,Modal,ModalHeader } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

export default function Staff() {
    document.title = "Staff | Newjo Insurance Agency";

    const [staff, setStaff] = useState([]);
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [fname, setFname] = useState('');
    const [mname, setMname] = useState('')
    const [lname,setLname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [role,setRole] = useState('')
    const [selectedStaff, setSelectedstaff] = useState('');
    const [submitText, setSubmittext] = useState('Submit Staff')
    const [updateText, setUpdatetext] = useState('Save changes')
    const [deleteText, setDeletetext] = useState('Remove staff')



    useEffect(()=>{
        const fetchStaff =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getStaff');
            const data = await res.json();
            setStaff(data)

        }

        fetchStaff()

    },[])

    const fetchStaff =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getStaff');
            const data = await res.json();
            setStaff(data)

        }

        const toggle =()=>{
        setModal(!modal)
    }


    const submitStaff =async()=>{
        if(fname !=='' && mname !== '' && lname !== '' && email !== '' && phone !== '' && role !== '' ){
            setSubmittext('Please wait...')
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/submitStaff',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({fname,mname,lname,email,phone,role}),
            credentials:"include"
        })

        const data = await res.json();
            const {success} = data;
            if(success == true){
                setSubmittext('Submit staff')
                toast.success('Staff added');
                fetchStaff();
                setModal(false)
            }
            else{
                setSubmittext('Submit staff')
                toast.error('Something went wrong!')
            }

        }
        else{
            toast.error("All fields are required")
        }
    }

    const toggle2 =async(e)=>{
        const id = e.target.value;
        setSelectedstaff(id)
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getThisstaff?id=${id}`);
        const data = await res.json();
        setFname(data.fname);
        setMname(data.mname)
        setLname(data.lname)
        setEmail(data.email)
        setPhone(data.phone)
        setRole(data.role)

        setModal2(!modal2)

    }

    const updateStaff =async()=>{
        setUpdatetext('Please wait...')
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/updateStaff',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({fname,mname,lname,email,phone,role,selectedStaff}),
            credentials:"include"
        })

        const data = await res.json();
            const {success} = data;
            if(success == true){
                setUpdatetext('Save changes')
                toast.success('Changes saved');
                fetchStaff();
                setModal2(false)
            }
            else{
                setUpdatetext('Save changes')
                toast.error('Something went wrong!')
            }
    }


    const removeStaff =async()=>{
        setDeletetext('Please wait')
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/deleteStaff?id=${selectedStaff}`);
        const data = await res.json();
        const {success} = data;
        if(success == true){
                setUpdatetext('Remove staff')
                toast.success('Staff removed');
                fetchStaff();
                setModal2(false)
            }
            else{
                setUpdatetext('Remove staff')
                toast.error('Something went wrong!')
            }

    }



  return (
    <div className='pagesContainer'>
        <ToastContainer />
            <Breadcrumbs title="Newjo" breadcrumbItem='Staff' />
        <div className="card">
        <div className='addButton'>
            <Button  color='success' onClick={toggle} >+ New staff</Button>
        </div>
        <div className="table-responsive">
                <Table striped bordered hover>

                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">First name</th>
                            <th scope="col">Middle name</th>
                            <th scope="col">Last name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Role</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {staff.map((val,index)=>{
                        
                            return(
                                <tr>
                                    <td>{index+1}</td>
                                   
                                    <td><button className='tableButton' value={val.id} onClick={toggle2}   >{val.fname}</button></td>
                                    <td>{val.mname}</td>
                                    <td>{val.lname}</td>
                                    <td>{val.email}</td>
                                    <td>{val.phone}</td>
                                    <td>{val.role}</td>
                                   
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </div>

        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader>New Company</ModalHeader>
            <div className="modalContainer">
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="First name" onChange={(e)=>{setFname(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={fname}  type="text" placeholder="First name" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Middle name" onChange={(e)=>{setMname(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={mname}  type="text" placeholder="Middle name" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Last name" onChange={(e)=>{setLname(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={lname}  type="text" placeholder="Last name" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Email" onChange={(e)=>{setEmail(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={email}  type="text" placeholder="Email" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Phone" onChange={(e)=>{setPhone(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={phone}  type="text" placeholder="Phone" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Role" onChange={(e)=>{setRole(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Role</option>
                        <option value='Admin'>Admin</option>
                        <option value='Operations'>Operations</option>

                    </Form.Select>
             </FloatingLabel>

               
                
                <Button color='success' style={{marginTop:'3%'}} onClick={submitStaff}>{submitText}</Button>
            </div>
        </Modal>


        <Modal isOpen={modal2} toggle={toggle2}>
            <ModalHeader>Edit user</ModalHeader>
            <div className="modalContainer">
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="First name" onChange={(e)=>{setFname(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={fname}  type="text" placeholder="First name" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Middle name" onChange={(e)=>{setMname(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={mname}  type="text" placeholder="Middle name" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Last name" onChange={(e)=>{setLname(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={lname}  type="text" placeholder="Last name" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Email" onChange={(e)=>{setEmail(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={email}  type="text" placeholder="Email" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Phone" onChange={(e)=>{setPhone(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={phone}  type="text" placeholder="Phone" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Role" onChange={(e)=>{setRole(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Role</option>
                        <option value='Admin'>Admin</option>
                        <option value='Operations'>Operations</option>

                    </Form.Select>
             </FloatingLabel>

               
                
             <div className="modalButtons">
                <Button color='success' style={{marginTop:'3%'}} onClick={updateStaff}>{updateText}</Button>
                <Button color='danger' style={{marginTop:'3%',marginLeft:"3%"}} onClick={removeStaff}>{deleteText}</Button>
                <Button color='danger' style={{marginTop:'3%',marginLeft:"3%"}} onClick={()=>setModal2(false)}>Close</Button>

                </div>
            </div>
        </Modal>
    </div>
  )
}
