import {React,useState,useEffect} from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Table from 'react-bootstrap/Table';
import { Button,Modal,ModalHeader,Label,Input } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';



export default function Premiums() {
    document.title = "Premiums | Newjo Insurance Agency";
    const [premiums, setPremiums] = useState([]);
    const[start,setStart] = useState('');
    const[end,setEnd] = useState('');
    const[search, setSearch] = useState('')

    useEffect(()=>{
        const getPremiums =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getPolicies');
            const data = await res.json();
            setPremiums(data)
        }

        getPremiums();

    },[])

    useEffect(()=>{
        const getPremiums =async()=>{
            const res = await fetch(`https://panel.yunshuglobal.com:3060/api/searchPolicynumber?search=${search}`);
            const data = await res.json();
            setPremiums(data)
        }

        if(search){
            getPremiums();
        }

        

    },[search])

    const handleDateChange = (e) => {
        const dateTime = e.target.value;
        const millisecondsSinceEpoch = new Date(dateTime).getTime();
        setStart(millisecondsSinceEpoch);
    
      };
      const handleDateChange2 = (e) => {
        const dateTime = e.target.value;
        const millisecondsSinceEpoch = new Date(dateTime).getTime();
        setEnd(millisecondsSinceEpoch);
       
      };


       const exportToPdf = () => {
              const doc = new jsPDF();
          
              // Add a title to the PDF
              doc.text("Newjo_Premiums", 20, 10);
          
              // Extract table headers and rows from the DOM
              const table = document.getElementById("dataTable");
              const headers = Array.from(
                table.querySelectorAll("thead tr th")
              ).map((header) => header.textContent);
          
              const rows = Array.from(table.querySelectorAll("tbody tr")).map((row) =>
                Array.from(row.querySelectorAll("td")).map((cell) => cell.textContent)
              );
          
              // Generate the PDF table
              doc.autoTable({
                head: [headers],
                body: rows,
                startY: 20,
              });
          
              // Save the PDF
              doc.save("Newjo_Premiums.pdf");
            };
    

  return (
    <div className='pagesContainer'>
        <Breadcrumbs title="Newjo" breadcrumbItem='Premiums received' />
        <div className="card">
            <div className='addButton'>
                <Button color='success' onClick={exportToPdf}>Export to PDF</Button>
            </div>
        <div className="filterBar">
            <div className="flt">
            <div className="mb-4" id='mb'>
                <Label className="form-label">From date</Label>
                <Input
                    name="text"
                    className="form-control"
                    placeholder="Search order"
                    type="datetime-local"
                    onChange={handleDateChange}
                    // value={email}

                />
                
            </div>
            <div className="mb-4" id='mb'>
                <Label className="form-label">To date</Label>
                <Input
                    name="text"
                    className="form-control"
                    placeholder="Search order"
                    type="datetime-local"
                    onChange={handleDateChange2}
                    // value={email}

                />
                
            </div>

            <div className="mb-4" id='mb'>
                <Label className="form-label">Search policy</Label>
                <Input
                    name="text"
                    className="form-control"
                    placeholder="Search polict"
                    type="text"
                    onChange={(e)=>{setSearch(e.target.value)}}
                    value={search}

                />
                
            </div>

            </div>

            
           
        </div>
        <div className="table-responsive">
                <Table striped bordered hover id="dataTable">

                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Date</th>
                            <th scope="col">Policy no.</th>
                            <th scope="col">Product</th>
                            <th scope="col">Sum insured(KES)</th>
                            <th scope="col">Gross premium(KES)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {premiums.map((val,index)=>{
                            const date = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.date)

                            if(start == '' && end == ''){
                                return(
                                <tr>
                                    <td>{index+1}</td>
                                    <td><button className='tableButton' value={val.id} >{val.client}</button></td>
                                    <td>{date}</td>
                                    <td>{val.policyNumber}</td>
                                    <td>{val.product}</td>
                                    <td>{val.sumInsured}</td>
                                    <td>{val.grossPremium}</td>
                                </tr>
                            )

                            }
                            else if( start < val.date && end > val.date){
                                return(
                                <tr>
                                    <td>{index+1}</td>
                                    <td><button className='tableButton' value={val.id} >{val.client}</button></td>
                                    <td>{date}</td>
                                    <td>{val.policyNumber}</td>
                                    <td>{val.product}</td>
                                    <td>{val.sumInsured}</td>
                                    <td>{val.grossPremium}</td>
                                </tr>
                            )

                            }

                           
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    </div>
  )
}
