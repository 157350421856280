import {React, useState, useEffect} from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Table from 'react-bootstrap/Table';
import { Button,Modal,ModalHeader } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

export default function Companies() {
    document.title = "Companies | Newjo Insurance Agency";

    const [companies, setCompanies] = useState([])
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [submitText, setSubmittext] = useState('Submit Company')
    const [updateText, setUpdatetext] = useState('Save changes')
    const [deleteText, setDeletetext] = useState('Remove company')
    const [selectedCompany, setSelectedcompany] = useState('')

    useEffect(()=>{
        const fetchCompanies =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getCompanies');
            const data = await res.json();
            setCompanies(data)

        }

        fetchCompanies()

    },[])

    const fetchCompanies =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getCompanies');
            const data = await res.json();
            setCompanies(data)

        }

    const toggle =()=>{
        setModal(!modal)
    }

        const submitCompany =async()=>{
        if(company !=='' && phone !== '' && email !== '' ){
            setSubmittext('Please wait...')
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/submitCompany',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({company,email,phone}),
            credentials:"include"
        })

        const data = await res.json();
            const {success} = data;
            if(success == true){
                setSubmittext('Submit company')
                toast.success('Company added');
                fetchCompanies();
                setModal(false)
            }
            else{
                setSubmittext('Submit Company')
                toast.error('Something went wrong!')
            }

        }
        else{
            toast.error("All fields are required")
        }
    }


    const toggle2 =async(e)=>{
        const id = e.target.value;
        setSelectedcompany(id)
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getThiscompany?id=${id}`);
        const data = await res.json();
        setCompany(data.companyName);
        setPhone(data.companyPhone)
        setEmail(data.companyEmail)

        setModal2(!modal2)

    }

    const updateCompany =async()=>{
        setUpdatetext('Please wait...')
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/updateCompany',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({company,email,phone,selectedCompany}),
            credentials:"include"
        })

        const data = await res.json();
            const {success} = data;
            if(success == true){
                setUpdatetext('Save changes')
                toast.success('Changes saved');
                fetchCompanies();
                setModal2(false)
            }
            else{
                setUpdatetext('Save changes')
                toast.error('Something went wrong!')
            }
    }


    const removeCompany =async()=>{
        setDeletetext('Please wait')
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/deleteCompany?id=${selectedCompany}`);
        const data = await res.json();
        const {success} = data;
        if(success == true){
                setUpdatetext('Remove company')
                toast.success('Company removed');
                fetchCompanies();
                setModal2(false)
            }
            else{
                setUpdatetext('Remove company')
                toast.error('Something went wrong!')
            }

    }



  return (
    <div className='pagesContainer'>
            <ToastContainer />
            <Breadcrumbs title="Newjo" breadcrumbItem='Companies' />
        <div className="card">
        <div className='addButton'>
            <Button  color='success' onClick={toggle} >+ New Company</Button>
        </div>
        <div className="table-responsive">
                <Table striped bordered hover>

                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Company</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Email</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {companies.map((val,index)=>{
                        
                            return(
                                <tr>
                                    <td>{index+1}</td>
                                   
                                    <td><button className='tableButton' value={val.id} onClick={toggle2}  >{val.companyName}</button></td>
                                    <td>{val.companyPhone}</td>
                                    <td>{val.companyEmail}</td>
                                   
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </div>


        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader>New Company</ModalHeader>
            <div className="modalContainer">
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Name" onChange={(e)=>{setCompany(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={company}  type="text" placeholder="Name" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Phone" onChange={(e)=>{setPhone(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={phone}  type="text" placeholder="Phone" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Email" onChange={(e)=>{setEmail(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={email}  type="text" placeholder="Email" />
                </FloatingLabel>

               
                
                <Button color='success' style={{marginTop:'3%'}} onClick={submitCompany}>{submitText}</Button>
            </div>
        </Modal>


        <Modal isOpen={modal2} toggle={toggle2}>
            <ModalHeader>Edit company</ModalHeader>
            <div className="modalContainer">
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Name" onChange={(e)=>{setCompany(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={company}  type="text" placeholder="Name" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Phone" onChange={(e)=>{setPhone(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={phone}  type="text" placeholder="Phone" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Email" onChange={(e)=>{setEmail(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={email}  type="text" placeholder="Email" />
                </FloatingLabel>

               
                
                <div className="modalButtons">
                <Button color='success' style={{marginTop:'3%'}} onClick={updateCompany}>{updateText}</Button>
                <Button color='danger' style={{marginTop:'3%',marginLeft:"3%"}} onClick={removeCompany}>{deleteText}</Button>
                <Button color='danger' style={{marginTop:'3%',marginLeft:"3%"}} onClick={()=>setModal2(false)}>Close</Button>

                </div>
            </div>
        </Modal>
    </div>
  )
}
