import {React, useState, useEffect} from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Table from 'react-bootstrap/Table';
import { Button,Modal,ModalHeader } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

export default function Renewals() {
    document.title = "Upcoming renewals | Newjo Insurance Agency";

    const [policies, setPolicies] = useState([]);
    const [selectedPolicy, setSelectedpolicy] = useState('');
    const [client, setClient] = useState('');
    const [product, setProduct] = useState('');
    const [expiryDate, setExpirydate] = useState('');
    const [duration, setDuration] = useState('Year');
    const [durationSeconds, setDurationseconds] = useState(31536000000);
    const [expiry, setExpiry] = useState('');
    const [modal,setModal] = useState(false);
    const [policyNumber, setPolicynumber] = useState('');
    const [premium, setPremium] = useState('');
    const [sumInsured, setSuminsured] = useState("")
    const [nextRenewal, setNextrenewal] = useState('')
    const [nextSeconds, setNextseconds] = useState('')
    const [renewText, setRenewtext] = useState('Renew policy')
    

    useEffect(()=>{
        const fetchPolicies =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getUpcomingrenewals');
            const data = await res.json();
            setPolicies(data)

        }

        
        fetchPolicies();
        

    },[])

    const fetchPolicies =async()=>{
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/getUpcomingrenewals');
        const data = await res.json();
        setPolicies(data)

    }

    const toggle =async(e)=>{
        const id = e.target.value;
        setSelectedpolicy(id)
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getPolicy?policy=${id}`);
        const data = await res.json();
        setClient(data.client)
        setProduct(data.product)
        setPolicynumber(data.policyNumber)
        setPremium(data.grossPremium)
        setSuminsured(data.sumInsured)
        setExpirydate(new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(data.expiryDate))
        setExpiry(data.expiryDate)
        setModal(!modal)

    }

    useEffect(()=>{

        if(duration && expiry && expiryDate){
            if(duration == 'Month'){
                setDurationseconds(2629746000)
                const nxt = parseInt(expiry) + durationSeconds;
                setNextseconds(nxt)
                setNextrenewal(new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(nextSeconds))
    
            }
            if(duration == 'Year'){
                setDurationseconds(31536000000)
                const nxt = parseInt(expiry) + durationSeconds;
                setNextseconds(nxt)
                setNextrenewal(new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(nextSeconds))
    
    
            }

        }

        

    },[duration,expiry,expiryDate,nextSeconds,expiry,durationSeconds])

    const renewPolicy=async()=>{
        setRenewtext('Please wait...')
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/renewPolicy2',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                selectedPolicy,nextSeconds,policyNumber,premium
            }),
            credentials:"include"
        })

        const data = await res.json();
        const {success} = data;
        if(success == true){
            setRenewtext('Renew policy')
            toast.success('Policy renewed');
            fetchPolicies();
            setModal(false)
        }
        else{
            setRenewtext('Renew policy')
            toast.error('Something went wrong!')
        }
    }








  return (
    <div className='pagesContainer'>
         <ToastContainer />
          <Breadcrumbs title="Newjo" breadcrumbItem={`Renewals(${policies.length})`} />
          <div className="card">
         
          <div className="table-responsive">
                <Table striped bordered hover>

                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Policy Number</th>
                            <th scope="col">Client</th>
                            <th scope="col">Product</th>
                            <th scope="col">Sum insured</th>
                            <th scope="col">Expiry date</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {policies.map((val,index)=>{
                            const expiryDate = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.expiryDate)   
                        return(
                        <tr>
                            <td>{index+1}</td>       
                            <td><button className='tableButton' value={val.id} onClick={toggle}>{val.policyNumber}</button></td>
                            <td>{val.client}</td>
                            <td>{val.product}</td>
                            <td>{val.sumInsured}</td>
                            <td>{expiryDate}</td>
                        </tr>
                            )                
                        })}
                    </tbody>
                </Table>
            </div>
          </div>

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader>{policyNumber}</ModalHeader>
            <div className="modalContainer">
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Policy number" >
                    <Form.Control  className='inputSearch' value={policyNumber}  type="text" placeholder="Policy number" disabled />
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Client" >
                    <Form.Control  className='inputSearch' value={client}  type="text" placeholder="Client" disabled />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Product" >
                    <Form.Control  className='inputSearch' value={product}  type="text" placeholder="Product" disabled />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Expiry date" >
                    <Form.Control  className='inputSearch' value={expiryDate}  type="text" placeholder="Expiry date" disabled />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Premium(Kes)" >
                    <Form.Control  className='inputSearch' value={premium}  type="text" placeholder="Premium" disabled />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Sum insured(Kes)" >
                    <Form.Control  className='inputSearch' value={sumInsured}  type="text" placeholder="Sum insured" disabled />
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Set duration" onChange={(e)=>{setDuration(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>setDuration</option>
                        <option value='Month'>Month</option>
                        <option value='Year'>Year</option>
                    </Form.Select>
                </FloatingLabel>
                 {duration == 'Month' && 
                 <>
                 <p>Next renewal: {nextRenewal}</p>

                 </>
                    
                 }
                 {duration == 'Year' && 
                    <>
                 <p>Next renewal: {nextRenewal}</p>

                 </>
                 }

                 


                <Button color='success' style={{marginTop:'3%'}} onClick={renewPolicy} >{renewText}</Button>
                <p style={{textAlign:'right',color:'red',cursor:'pointer'}} onClick={()=>setModal(false)}>Close</p>
            </div>
          </Modal>
    </div>
  )
}
