import {React, useState, useEffect} from 'react';
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Table from 'react-bootstrap/Table';
import { Button,Modal,ModalHeader } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

export default function Terminatedpolicies() {
    document.title = "Expired/terminated policies | Newjo Insurance Agency";

    const [policies, setPolicies] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedPolicy, setSelectedpolicy] = useState('');

    const fetchPolicies =async()=>{
        const res= await fetch('https://panel.yunshuglobal.com:3060/api/getExpiredpolicies');
        const data = await res.json();
        setPolicies(data)

    }

    useEffect(()=>{

        const fetchPolicies =async()=>{
            const res= await fetch('https://panel.yunshuglobal.com:3060/api/getExpiredpolicies');
            const data = await res.json();
            setPolicies(data)

        }

        fetchPolicies()

    },[])

    const toggle =async(e)=>{
        const id = e.target.value;
        setSelectedpolicy(id);
        setModal(!modal)
    }

    const renew =async()=>{
      
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/renewPolicy',{
            method:'POST',
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({selectedPolicy}),
            credentials:"include"

        });

        const data = await res.json();
        const {success} = data;
        if(success == true){
            fetchPolicies()
            toast.success('Policy has been renew. Please proceed to approve');
            
          
            setModal(false)
        }
        else{
         
            toast.error('Something went wrong')
        }


    }

  return (
    <div className='pagesContainer'>
         <Breadcrumbs title="Newjo" breadcrumbItem='Expired/Terminated policies' />
         <div className="card">
         <div className="table-responsive">
                <Table striped bordered hover>

                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Date</th>
                            <th scope="col">Policy Number</th>
                            <th scope="col">Client</th>
                            <th scope="col">Insurer</th>
                            <th scope="col">Product</th>
                            <th scope="col">Insured Item</th>
                            <th scope="col">Expiry</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {policies.map((val,index)=>{
                            const policyDate = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.date)
                            const expiryDate = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.expiryDate)
                            return(
                                <tr>
                                    <td>{index+1}</td>
                                    <td>{policyDate}</td>
                                    <td><button className='tableButton' onClick={toggle} value={val.id} >{val.policyNumber}</button></td>
                                    <td>{val.client}</td>
                                    <td>{val.insurer}</td>
                                    <td>{val.product}</td>
                                    <td>{val.registrationNo}</td>
                                    <td>{expiryDate}</td>
                                    <td>{val.status}</td>
                                    

                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
         </div>

         <Modal isOpen={modal} toggle = {toggle} >
            <ModalHeader>Policy details</ModalHeader>
            <div className="modalContainer">
            <div className="modalButtons">
                <Button style={{marginTop:'3%'}} color='danger' onClick={()=>setModal(false)}>Close</Button>

                <Button style={{marginTop:'3%',marginLeft:'2%'}}  color='success' onClick={renew}  >Renew policy</Button>
                </div>
            
            </div>

         </Modal>
    </div>
  )
}
