import {React,useState,useEffect} from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Table from 'react-bootstrap/Table';
import { Button,Modal,ModalHeader,Label,Input } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Commission() {
     document.title = "Commissions | Newjo Insurance Agency";
        const [premiums, setPremiums] = useState([]);
        const[start,setStart] = useState('');
        const[end,setEnd] = useState('');
        const[search, setSearch] = useState('');
        const[received, setReceived] = useState(0);
        const[payment, setPayment] = useState(0);
        const[modal, setModal] = useState(false);
        const[comm, setComm] = useState('')
        const[bonus, setBonus] = useState(0)
        const[selectedPolicy, setSelectedpolicy] = useState('');
        const [updateText, setUpdatetext] = useState('Submit')

        useEffect(()=>{
            const getPremiums =async()=>{
                const res = await fetch('https://panel.yunshuglobal.com:3060/api/getPolicies');
                const data = await res.json();
                setPremiums(data)
            }
    
            getPremiums();
    
        },[])
        const getPremiums =async()=>{
                const res = await fetch('https://panel.yunshuglobal.com:3060/api/getPolicies');
                const data = await res.json();
                setPremiums(data)
            }
    
        useEffect(()=>{
            const getPremiums =async()=>{
                const res = await fetch(`https://panel.yunshuglobal.com:3060/api/searchPolicynumber?search=${search}`);
                const data = await res.json();
                setPremiums(data)
            }
    
            if(search){
                getPremiums();
            }
    
            
    
        },[search])
    
        const handleDateChange = (e) => {
            const dateTime = e.target.value;
            const millisecondsSinceEpoch = new Date(dateTime).getTime();
            setStart(millisecondsSinceEpoch);
        
          };
          const handleDateChange2 = (e) => {
            const dateTime = e.target.value;
            const millisecondsSinceEpoch = new Date(dateTime).getTime();
            setEnd(millisecondsSinceEpoch);
           
          };
    
    
           const exportToPdf = () => {
                  const doc = new jsPDF();
              
                  // Add a title to the PDF
                  doc.text("Newjo_Commissions", 20, 10);
              
                  // Extract table headers and rows from the DOM
                  const table = document.getElementById("dataTable");
                  const headers = Array.from(
                    table.querySelectorAll("thead tr th")
                  ).map((header) => header.textContent);
              
                  const rows = Array.from(table.querySelectorAll("tbody tr")).map((row) =>
                    Array.from(row.querySelectorAll("td")).map((cell) => cell.textContent)
                  );
              
                  // Generate the PDF table
                  doc.autoTable({
                    head: [headers],
                    body: rows,
                    startY: 20,
                  });
              
                  // Save the PDF
                  doc.save("Newjo_Commissions.pdf");
                };
        

 const toggle = async(e)=>{
    const id = e.target.value;
    setSelectedpolicy(id)
    const res  = await fetch(`https://panel.yunshuglobal.com:3060/api/searchPolicy?policy=${id}`);
    const data = await res.json();
    setComm(data.standardCommission);
    setReceived(data.received)
    setModal(!modal)
    
 }

 const postPayment =async()=>{
    if(payment !== 0){
        setUpdatetext('Please wait...')
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/updatePayment',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({selectedPolicy,payment,bonus}),
            credentials:"include"

        })

        const data = await res.json();
        const {success} = data;
        if(success == true){
            setUpdatetext('Submit')
            toast.success('Payment saved');
            getPremiums();
            setPayment(0)
            setBonus(0)
            setModal(false)
        }
        else{
            setUpdatetext('Submit')
            toast.error('Something went wrong!')
        }

    }
    else{
        toast.error('Please enter amount and bonus if applicable!')

    }
 }

    
  return (
    <div className='pagesContainer'>
    <ToastContainer />
        <Breadcrumbs title="Newjo" breadcrumbItem='Commission received' />
        <div className="card">
            <div className='addButton'>
                <Button color='success' onClick={exportToPdf}>Export to PDF</Button>
            </div>
        <div className="filterBar">
            <div className="flt">
            <div className="mb-4" id='mb'>
                <Label className="form-label">From date</Label>
                <Input
                    name="text"
                    className="form-control"
                    placeholder="Search order"
                    type="datetime-local"
                    onChange={handleDateChange}
                    // value={email}

                />
                
            </div>
            <div className="mb-4" id='mb'>
                <Label className="form-label">To date</Label>
                <Input
                    name="text"
                    className="form-control"
                    placeholder="Search order"
                    type="datetime-local"
                    onChange={handleDateChange2}
                    // value={email}

                />
                
            </div>

            <div className="mb-4" id='mb'>
                <Label className="form-label">Search policy</Label>
                <Input
                    name="text"
                    className="form-control"
                    placeholder="Search polict"
                    type="text"
                    onChange={(e)=>{setSearch(e.target.value)}}
                    value={search}

                />
                
            </div>

            </div>

            
           
        </div>
        <div className="table-responsive">
                <Table striped bordered hover id="dataTable">

                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Date</th>
                            <th scope="col">Policy no.</th>
                            <th scope="col">Sum insured(KES)</th>
                            <th scope="col">Premium(KES)</th>
                            <th scope="col">Commission(KES)</th>
                            <th scope="col">Received(KES)</th>
                            <th scope="col">Bonus(KES)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {premiums.map((val,index)=>{
                            const date = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.date)

                            if(start == '' && end == ''){
                                return(
                                <tr>
                                    <td>{index+1}</td>
                                    <td><button className='tableButton' value={val.policyNumber} onClick={toggle} >{val.client}</button></td>
                                    <td>{date}</td>
                                    <td>{val.policyNumber}</td>
                                    <td>{val.sumInsured}</td>
                                    <td>{val.grossPremium}</td>
                                    <td>{val.standardCommission}</td>
                                    <td>{val.received}</td>
                                    <td>{val.bonus}</td>
                                </tr>
                            )

                            }
                            else if( start < val.date && end > val.date){
                                return(
                                <tr>
                                    <td>{index+1}</td>
                                    <td><button className='tableButton' value={val.id} >{val.client}</button></td>
                                    <td>{date}</td>
                                    <td>{val.policyNumber}</td>
                                    <td>{val.product}</td>
                                    <td>{val.sumInsured}</td>
                                    <td>{val.grossPremium}</td>
                                </tr>
                            )

                            }

                           
                        })}
                    </tbody>
                </Table>
            </div>
        </div>

        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader>Receive commission</ModalHeader>
            <div className="modalContainer">
                <p>Commission: Kes. {comm}</p>
                <p>Received: Kes. {received}</p>
                <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Received amount" onChange={(e)=>{setPayment(e.target.value)}}>
                <Form.Control  className='inputSearch' value={payment}  type="number" placeholder="Receive amount" />
                </FloatingLabel>
                <FloatingLabel  style={{marginTop:'2%'}} className='formInput' controlId="floatingInputGrid" label="Bonus" onChange={(e)=>{setBonus(e.target.value)}}>
                <Form.Control  className='inputSearch' value={bonus}  type="number" placeholder="Bonus" />
                </FloatingLabel>

                <div className='addButton' style={{marginTop:'2%'}}>
                <Button color='success' onClick={postPayment}>{updateText}</Button>
                <Button color='error' onClick={()=>{setModal(false)}}>Close</Button>

            </div>
            </div>

        </Modal>
    </div>
  )
}
