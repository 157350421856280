import React from 'react'
import { useState,useEffect } from 'react';
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Table from 'react-bootstrap/Table';
import { Button,Modal,ModalHeader } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Axios from 'axios'

export default function Clients() {
    document.title = "Clients | Newjo Insurance Agency";

    const [clients, setClients] = useState([]);
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [accountType, setAccounttype] = useState('Individual');
    const [fname, setFname] = useState('');
    const [mname, setMname] = useState('');
    const [lname, setLname] = useState('');
    const [regNumber, setRegnumber] = useState('')
    const [pin, setPin] = useState('')
    const [address, setAddress] = useState('');
    const [gender, setGender] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [companyName, setCompanyname] = useState('');
    const [companyReg, setCompanyreg] = useState('');
    const [companyPin, setCompanypin] = useState('');
    const [companyAddress, setCompanyaddress] = useState('');
    const [companyPhone, setCompanyphone] = useState('');
    const [companyEmail, setComponyemail] = useState('');
    const [selectedClient, setSelectedclient] = useState('')
    const [submiteText, setSubmittext] = useState('Submit');
    const [updateText, setUpdatetext] = useState('Save changes');
    const [deleteText, setDeletetext] = useState('Remove client');
    const [deleteClaimtext, setClaimtext] = useState('Remove claim');
    const [files, setFiles] = useState([]);
    const [message, setMessage] = useState("")
    const [bool, setBool] = useState('')
    const [tab, setTab] = useState(1)
    const [documentsMessage,setDocumentsmessage] = useState('')
    const [file, setFile] = useState(null)
    const [companies, setCompanies] = useState([])
    const [claims, setClaims] = useState([]);
    const [modal3,setModal3] = useState(false);
    const [client, setClient] = useState('');
    const [product, setProduct] = useState('');
    const [insurer, setInsurer] = useState('');
    const [status, setStatus] = useState('');
    const [sumInsured, setSuminsured] = useState('')
    const [loss, setLoss] = useState('');
    const [naration, setNaration] = useState('');
    const [occurenceDate, setOccurencedate] = useState('')
    const [selectedClaim, setSelectedclaim] = useState('');
    const [claimTab, setClaimtab] = useState(1);
    const [shareEmail, setShareemail] = useState('')
    const [identification, setIdentification] = useState('')
     const [policyNumber, setPolicynumber] = useState('');


    const tab1 =()=>{
        setClaimtab(1)
    }
    const tab2 =()=>{
        setClaims(2)
    }
    const tab3 =()=>{
        setClaimtab(3)
    }



    const fetchFiles =async()=>{
        if(accountType == 'Individual'){
          
            const res = await fetch(`https://panel.yunshuglobal.com:3060/api/checkDocuments/${email}`); 
            const data = await res.json();
            setFiles(data.files);
            setDocumentsmessage('')
           
        }
        if(accountType == 'Corporate'){

            const res = await fetch(`https://panel.yunshuglobal.com:3060/api/checkDocuments/${companyEmail}`); 
            const data = await res.json();
            setFiles(data.files);  
            setDocumentsmessage('') 
        }
    }

    const clickTab1 =()=>{
        setTab(1)
    }
    const clickTab2 =async()=>{
        setTab(2)
       
        if(accountType == 'Individual'){
            setDocumentsmessage('Searching for documents...');
            const res = await fetch(`https://panel.yunshuglobal.com:3060/api/checkDocuments/${email}`); 
            const data = await res.json();
            setFiles(data.files);
            if(data.message == 'Folder is empty'){
                setDocumentsmessage('No documents found')
            }
            else if(data.message == 'Files found'){
                setDocumentsmessage('')
            }
        }
        if(accountType == 'Corporate'){
            setDocumentsmessage('Searching for documents...');
            const res = await fetch(`https://panel.yunshuglobal.com:3060/api/checkDocuments/${companyEmail}`); 
            const data = await res.json();
            setFiles(data.files);
          
            if(data.message == 'Folder is empty'){
                setDocumentsmessage('No documents found')
            }
            else if(data.message == 'Files found'){
                setDocumentsmessage('')
            }
        }

    }
    const clickTab3 =()=>{
        setTab(3)
    }
    const clickTab4 =()=>{
        setTab(4)
    }

    

    const toggle=()=>{
        setModal(!modal)
    }

    const handleFileUpload = (event) => {
        
        const fl = event.target.files[0]; 
        setFile(fl)
        
        };

    const uploadFile = async () => {
            const formData = new FormData();
            
            if(accountType == 'Individual'){
                formData.append('email', email); 
            }
            else if(accountType == 'Corporate'){
                formData.append('email', companyEmail); 
            }

            formData.append('file', file); 

        try {
            
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/uploadFile', {
                method: "POST",
                body: formData, // Pass the FormData object directly
                credentials: "include",
            });

            const data = await res.json();
            const{success} = data;
            if(success == true){
                fetchFiles()
                toast.success('File uploaded successfully')
            }
            else{
                toast.error('Something went wrong')
            }
           
        } catch (error) {
            toast.error('Server is unreachable')
        }
};



    useEffect(()=>{
        const fetchData = async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getClients');
            const data = await res.json();
            setClients(data)
        }

        const fetchCompanies = async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getCompanies');
            const data = await res.json();
            setCompanies(data)
        }

        fetchData();
        fetchCompanies()

    },[])

    const fetchData = async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getClients');
            const data = await res.json();
            setClients(data)
        }


    const submitData =async()=>{
        if(accountType == 'Individual'){
            if(fname !== '' && mname !== '' && lname !== '' && regNumber !== '' && pin !== '' && address !== '' && phone !== '' && email !== '' && gender !== ''){
                setSubmittext('Please wait...')
                const formattedPhone = phone.replace(/^0/, '254');
                const res = await fetch('https://panel.yunshuglobal.com:3060/api/postIndividual',{
                    method:'POST',
                    headers:{
                        "Content-Type":"application/json"
                    },
                    body:JSON.stringify({
                        fname,mname,lname,regNumber,pin,email,phone:formattedPhone,gender,accountType,address
                    }),
                    credentials:'include'
                })

                const data = await res.json();
                const {success,exists} = data;

                if(success == true && exists == false){
                    const sendNotification = await Axios.post('https://n8n.uidevlabs.com/webhook/6dd1f027-d3bf-4f70-8177-b3b307de987c',{email,formattedPhone,fname})
                    setSubmittext('submit')
                    setFname("")
                    setMname("")
                    setLname("")
                    setRegnumber("")
                    setPin("")
                    setPhone("")
                    setGender("")
                    setAddress("")
                    fetchData();
                    setModal(false);
                    toast.success('Client saved')
                    
                }
                else if(success == false && exists == true){
                    setSubmittext('submit')
                    toast.error('Account credentials already exist!')
                }
                else{
                    setSubmittext('submit')
                    toast.error('Something went wrong!')
                }

            }
            else{
                toast.error('All fields are required')
            }
        }
        if(accountType == 'Corporate'){
            if(companyName !== '' && companyReg !== '' && companyPin !== '' && companyEmail !== '' && companyPhone !== '' && companyAddress !== ''){
                setSubmittext('Please wait...')
                const formattedPhone = companyPhone.replace(/^0/, '254');
                const res = await fetch('https://panel.yunshuglobal.com:3060/api/postCorporate',{
                    method:'POST',
                    headers:{
                        "Content-Type":"application/json"
                    },
                    body:JSON.stringify({
                        companyName,companyReg,companyPin,companyAddress,companyEmail,companyPhone:formattedPhone,accountType
                    }),
                    credentials:'include'
                })

                const data = await res.json();
                const {success,exists} = data;

                if(success == true && exists == false){
                    const sendNotification = await Axios.post('https://n8n.uidevlabs.com/webhook/6dd1f027-d3bf-4f70-8177-b3b307de987c',{email:companyEmail,formattedPhone,fname:companyName})
                    setSubmittext('submit')
                    setCompanyname("")
                    setCompanyreg("")
                    setCompanypin("")
                    setCompanyaddress("")
                    setComponyemail("")
                    setCompanyphone("")
                    fetchData();
                    setModal(false);
                    toast.success('Client saved')
                    
                }
                else if(success == false && exists == true){
                    setSubmittext('submit')
                    toast.error('Account credentials already exist!')
                }
                else{
                    setSubmittext('submit')
                    toast.error('Something went wrong!')
                }

            }
            else{
                toast.error('All fields are required')
            }
        }
    }


    const saveChanges =async()=>{
        if(accountType == 'Individual'){
           
                setUpdatetext('Please wait...')
                const formattedPhone = phone.replace(/^0/, '254');
                const res = await fetch('https://panel.yunshuglobal.com:3060/api/updateIndividual',{
                    method:'POST',
                    headers:{
                        "Content-Type":"application/json"
                    },
                    body:JSON.stringify({
                        fname,mname,lname,regNumber,pin,email,phone:formattedPhone,gender,accountType,address,selectedClient
                    }),
                    credentials:'include'
                })

                const data = await res.json();
                const {success,exists} = data;

                if(success == true){
                    fetchData()
                    setUpdatetext('Save changes')
                    toast.success('Changes saved')
                    
                }
                
                else{
                    setUpdatetext('Save changes')
                    toast.error('Something went wrong!')
                }


        }
        if(accountType == 'Corporate'){
           
                setUpdatetext('Please wait...')
                const formattedPhone = companyPhone.replace(/^0/, '254');
                const res = await fetch('https://panel.yunshuglobal.com:3060/api/updateCorporate',{
                    method:'POST',
                    headers:{
                        "Content-Type":"application/json"
                    },
                    body:JSON.stringify({
                        companyName,companyReg,companyPin,companyAddress,companyEmail,companyPhone:formattedPhone,selectedClient
                    }),
                    credentials:'include'
                })

                const data = await res.json();
                const {success,exists} = data;

                if(success == true){
                    fetchData()
                    setUpdatetext('Save changes')
                    toast.success('Changes saved')
                    
                }
                
                else{
                    setUpdatetext('Save changes')
                    toast.error('Something went wrong!')
                }


        }
    }


    const toggle2 =async(e)=>{
        const id = e.target.value;
        setSelectedclient(id)
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getThisclient?id=${id}`);
        const data = await res.json();
        setAccounttype(data.type)
        setBool(data.type)

        setFname(data.fname);
        setMname(data.otherName);
        setLname(data.lname);
        setRegnumber(data.idNumber);
        setPin(data.pinNumber);
        setAddress(data.address);
        setPhone(data.phone)
        setEmail(data.email);
        setGender(data.gender)

        setCompanyname(data.fname);
        setCompanyreg(data.idNumber);
        setCompanypin(data.pinNumber);
        setCompanyaddress(data.address);
        setCompanyphone(data.phone)
        setComponyemail(data.email);

       

       

        setModal2(!modal2)
        

    }

    const toggle3 =async(e)=>{
        const id = e.target.value;
        setSelectedclaim(id)
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getThisclaim?id=${id}`);
        const data = await res.json();
        setClient(data.client);
        setInsurer(data.insurer);
        setProduct(data.product);
        setSuminsured(data.sumInsured);
        setStatus(data.status);
        setPolicynumber(data.policyNumber)
        setLoss(data.loss)
        setNaration(data.naration)
        setIdentification(data.identification)
        setOccurencedate(new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(data.date))
        setModal3(!modal3)
    }

    useEffect(()=>{
        const getClaims =async()=>{
            const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getClientclaims?idNumber=${regNumber}`);
            const data = await res.json();
            setClaims(data)

        }

        if(regNumber){
            getClaims()
        }

    },[regNumber])

    const close2 =()=>{
        setCompanyname("")
        setCompanyreg("")
        setCompanypin("")
        setCompanyaddress("")
        setComponyemail("")
        setCompanyphone("")
        setFname("")
        setMname("")
        setLname("")
        setRegnumber("")
        setPin("")
        setPhone("")
        setGender("")
        setAddress("")
        setTab(1)
        setModal2(false)

    }


    const deleteClient =async()=>{
        setDeletetext('Please wait...')
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/deleteClient?id=${selectedClient}`);
        const data = await res.json();
        const {success,hasPolicy} = data;

        if(hasPolicy ==  true && success == false){
            setDeletetext('Remove client')
            toast.error('This client has an active policy. Please terminate the policy first.')
        }
        else if( hasPolicy ==  false && success == true){
            setDeletetext('Remove client')
            toast.success('Client has been deleted');
            fetchData();
            close2();
        }
        else{
            setDeletetext('Remove client')
            toast.error('Something went wrong!');
        }
    }

    const share=()=>{
        toast.error('Email gateway not available')
    }

        const updateClaim =async()=>{
            setUpdatetext('Please wait...')
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/updateClaim',{
                method:"POST",
                headers:{
                    "Content-Type":"application/json"
                },
                body:JSON.stringify({status,selectedClaim}),
                credentials:"include"
            })
    
            const data = await res.json();
                const {success} = data;
                if(success == true){
                    setUpdatetext('Save changes')
                    toast.success('Changes saved');
                   
                    setModal3(false)
                }
                else{
                    setUpdatetext('Save changes')
                    toast.error('Something went wrong!')
                }
        }
    
        const removeClaim =async()=>{
            setClaimtext('Please wait')
            const res = await fetch(`https://panel.yunshuglobal.com:3060/api/deleteClaim?id=${selectedClaim}`);
            const data = await res.json();
            const {success} = data;
            if(success == true){
                    setClaimtext('Remove claim')
                    toast.success('Claim removed');
                   
                    setModal3(false)
                }
                else{
                    setClaimtext('Remove claim')
                    toast.error('Something went wrong!')
                }
    
        }


  return (
    <div className='pagesContainer'>
    <ToastContainer />
          <Breadcrumbs title="Newjo" breadcrumbItem={`Clients(${clients.length})`} />
          <div className="card">
          <div className='addButton'>
            <Button  color='success' onClick={toggle}>+ New client</Button>
          </div>
          
            <div className="table-responsive">
                <Table striped bordered hover>

                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Type</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            
                            <th scope="col">Identity No.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clients.map((val,index)=>{
                          
                            return(
                                <tr>
                                    <td>{index+1}</td>
                                    <td><button className='tableButton' value={val.id} onClick={toggle2}>{val.fname} {val.otherName}</button></td>
                                    <td>{val.type}</td>
                                    <td>{val.email}</td>
                                    <td>{val.phone}</td>
                                    <td>{val.idNumber}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
          </div>



    <Modal isOpen={modal} toggle={toggle} size='lg'>
        <ModalHeader>Add new client</ModalHeader>
        <div className="modalContainer">
        <FloatingLabel className='formInput' controlId="floatingSelectGrid" label="Select account type">
            <Form.Select
                className='inputSearch'
               
                onChange={(e) => setAccounttype(e.target.value)}
            >
            <option selected disabled>Select account type</option>
            <option value="Individual">Individual</option>
            <option value="Corporate">Corporate</option>
           
            </Form.Select>
        </FloatingLabel>

        {accountType == 'Individual' &&
        <>
        <div className="inputs">
            <FloatingLabel className='formInput' controlId="floatingInputGrid" label="First name" onChange={(e)=>{setFname(e.target.value)}}>
                <Form.Control  className='inputSearch' value={fname}  type="text" placeholder="First name" />
            </FloatingLabel>

            <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Middle name" onChange={(e)=>{setMname(e.target.value)}}>
                <Form.Control  className='inputSearch' value={mname}  type="text" placeholder="Middle name" />
            </FloatingLabel>

            <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Last name" onChange={(e)=>{setLname(e.target.value)}}>
                <Form.Control  className='inputSearch' value={lname}  type="text" placeholder="Last name" />
            </FloatingLabel>

        </div>

        <div className="inputs">
            <FloatingLabel className='formInput' controlId="floatingInputGrid" label="ID Number" onChange={(e)=>{setRegnumber(e.target.value)}}>
                <Form.Control  className='inputSearch' value={regNumber}  type="text" placeholder="ID Number" />
            </FloatingLabel>

            <FloatingLabel className='formInput' controlId="floatingInputGrid" label="KRA Pin" onChange={(e)=>{setPin(e.target.value)}}>
                <Form.Control  className='inputSearch' value={pin}  type="text" placeholder="KRA Pin" />
            </FloatingLabel>

            <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Address" onChange={(e)=>{setAddress(e.target.value)}}>
                <Form.Control  className='inputSearch' value={address}  type="text" placeholder="Address" />
            </FloatingLabel>

        </div>

        <div className="inputs">
            <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Email" onChange={(e)=>{setEmail(e.target.value)}}>
                <Form.Control  className='inputSearch' value={email}  type="text" placeholder="Email" />
            </FloatingLabel>

            <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Phone number" onChange={(e)=>{setPhone(e.target.value)}}>
                <Form.Control  className='inputSearch' value={phone}  type="text" placeholder="Phone number" />
            </FloatingLabel>

            <FloatingLabel className='formInput' controlId="floatingSelectGrid" label="Gender">
            <Form.Select
                className='inputSearch'
               
                onChange={(e) => setGender(e.target.value)}
            >
            <option selected disabled>Select gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
           
            </Form.Select>
        </FloatingLabel>

        </div>
        <div className="">
            <Button onClick={submitData} color='success' style={{marginTop:'3%'}}>{submiteText}</Button>
        </div>
        
        </>
        
        }
        {accountType == 'Corporate' &&
        <>
        <div className="inputs">
            <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Company name" onChange={(e)=>{setCompanyname(e.target.value)}}>
                <Form.Control  className='inputSearch' value={companyName}  type="text" placeholder="Companyname" />
            </FloatingLabel>

            <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Registration number" onChange={(e)=>{setCompanyreg(e.target.value)}}>
                <Form.Control  className='inputSearch' value={companyReg}  type="text" placeholder="Registration number" />
            </FloatingLabel>

            <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Company pin" onChange={(e)=>{setCompanypin(e.target.value)}}>
                <Form.Control  className='inputSearch' value={companyPin}  type="text" placeholder="Company pin" />
            </FloatingLabel>

        </div>

        <div className="inputs">
            <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Address" onChange={(e)=>{setCompanyaddress(e.target.value)}}>
                <Form.Control  className='inputSearch' value={companyAddress}  type="text" placeholder="Address" />
            </FloatingLabel>

            <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Phone number" onChange={(e)=>{setCompanyphone(e.target.value)}}>
                <Form.Control  className='inputSearch' value={companyPhone}  type="text" placeholder="Phone number" />
            </FloatingLabel>

            <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Email" onChange={(e)=>{setComponyemail(e.target.value)}}>
                <Form.Control  className='inputSearch' value={companyEmail}  type="text" placeholder="Email" />
            </FloatingLabel>

        </div>

        <div className="">
            <Button onClick={submitData} color='success' style={{marginTop:'3%'}}>{submiteText}</Button>
        </div>
        </>
        
        }
        

        </div>
    </Modal>

    <Modal isOpen={modal2} toggle={toggle2} size='lg' >


            <ModalHeader>Account settings</ModalHeader>
        
        <div className="modalContainer">
            <div className="modalMenu">
                <p onClick={clickTab1}>Personal Info</p>
                <p  onClick={clickTab2}>Documents</p>
                <p  onClick={clickTab3}>Share</p>
                <p  onClick={clickTab4}>Claims history</p>
            </div>
            
            {tab == 1 &&
            <>
                <h6>Personal Information</h6>
                <p>Account type: {accountType}</p>
                {accountType == 'Individual' &&
                <>
                <div className="inputs">
                    <FloatingLabel className='formInput' controlId="floatingInputGrid" label="First name" onChange={(e)=>{setFname(e.target.value)}}>
                        <Form.Control  className='inputSearch' value={fname}  type="text" placeholder="First name" />
                    </FloatingLabel>

                    <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Middle name" onChange={(e)=>{setMname(e.target.value)}}>
                        <Form.Control  className='inputSearch' value={mname}  type="text" placeholder="Middle name" />
                    </FloatingLabel>

                    <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Last name" onChange={(e)=>{setLname(e.target.value)}}>
                        <Form.Control  className='inputSearch' value={lname}  type="text" placeholder="Last name" />
                    </FloatingLabel>

                </div>

                <div className="inputs">
                    <FloatingLabel className='formInput' controlId="floatingInputGrid" label="ID Number" onChange={(e)=>{setRegnumber(e.target.value)}}>
                        <Form.Control  className='inputSearch' value={regNumber}  type="text" placeholder="ID Number" disabled/>
                    </FloatingLabel>

                    <FloatingLabel className='formInput' controlId="floatingInputGrid" label="KRA Pin" onChange={(e)=>{setPin(e.target.value)}}>
                        <Form.Control  className='inputSearch' value={pin}  type="text" placeholder="KRA Pin" disabled />
                    </FloatingLabel>

                    <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Address" onChange={(e)=>{setAddress(e.target.value)}}>
                        <Form.Control  className='inputSearch' value={address}  type="text" placeholder="Address" />
                    </FloatingLabel>

                </div>

                <div className="inputs">
                    <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Email" onChange={(e)=>{setEmail(e.target.value)}}>
                        <Form.Control  className='inputSearch' value={email}  type="text" placeholder="Email" />
                    </FloatingLabel>

                    <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Phone number" onChange={(e)=>{setPhone(e.target.value)}}>
                        <Form.Control  className='inputSearch' value={phone}  type="text" placeholder="Phone number" />
                    </FloatingLabel>

                    <FloatingLabel className='formInput' controlId="floatingSelectGrid" label="Gender">
                    <Form.Select
                        className='inputSearch'
                    
                        onChange={(e) => setGender(e.target.value)}
                    >
                    <option selected disabled>{gender}</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                
                    </Form.Select>
                </FloatingLabel>

                </div>
                <div className="">
                    <Button onClick={saveChanges} color='success' style={{marginTop:'3%'}}>{updateText}</Button>
                </div>
                
                </>
                
               }

               {accountType == 'Corporate' &&
        <>
                <div className="inputs">
                    <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Company name" onChange={(e)=>{setCompanyname(e.target.value)}}>
                        <Form.Control  className='inputSearch' value={companyName}  type="text" placeholder="Companyname" />
                    </FloatingLabel>

                    <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Registration number" onChange={(e)=>{setCompanyreg(e.target.value)}}>
                        <Form.Control  className='inputSearch' value={companyReg}  type="text" placeholder="Registration number" disabled />
                    </FloatingLabel>

                    <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Company pin" onChange={(e)=>{setCompanypin(e.target.value)}}>
                        <Form.Control  className='inputSearch' value={companyPin}  type="text" placeholder="Company pin" disabled />
                    </FloatingLabel>

                </div>

                <div className="inputs">
                    <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Address" onChange={(e)=>{setCompanyaddress(e.target.value)}}>
                        <Form.Control  className='inputSearch' value={companyAddress}  type="text" placeholder="Address" />
                    </FloatingLabel>

                    <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Phone number" onChange={(e)=>{setCompanyphone(e.target.value)}}>
                        <Form.Control  className='inputSearch' value={companyPhone}  type="text" placeholder="Phone number" />
                    </FloatingLabel>

                    <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Email" onChange={(e)=>{setComponyemail(e.target.value)}}>
                        <Form.Control  className='inputSearch' value={companyEmail}  type="text" placeholder="Email" />
                    </FloatingLabel>

                </div>

                <div className="">
                    <Button onClick={saveChanges} color='success' style={{marginTop:'3%'}}>{updateText}</Button>
                </div>
                </>
                
                }

                <div className="">
                    <Button onClick={deleteClient} color='danger' style={{marginTop:'3%'}}>{deleteText}</Button>
                </div>
            </>
            }
            {tab == 2 &&
            <>
                <h6>Uploaded documents</h6>
                <p>{documentsMessage}</p>
                {files.length > 0 && (
                    <ul>
                    {files.map((file) => (
                        <li key={file.name}>
                        <a href={file.url} target="_blank" rel="noopener noreferrer">
                            {file.name}
                        </a>
                        </li>
                    ))}
                    </ul>
                )}

                <FloatingLabel className='formInput' controlId="floatingInputGrid" label="Choose file" onChange={(e)=> setFile(e.target.files[0])}>
                        <Form.Control  className='inputSearch'   type="file" placeholder="Document" />
                    </FloatingLabel>

               <Button style={{marginTop:'3%'}} onClick={uploadFile} color='success'>Upload Document</Button>
            </>
            }
            {tab == 3 &&
            <>
                <h6>Share details with insurer</h6>
                <FloatingLabel className='formInput' controlId="floatingSelectGrid" label="Insurance companies">
                    <Form.Select
                        className='inputSearch'
                    
                       
                    >
                    <option selected disabled>Select insurance company</option>
                    {companies.map((val)=>{
                        return(
                            <option>{val.companyName}</option>
                        )
                    })}
                
                    </Form.Select>
                </FloatingLabel>

                <Button style={{marginTop:'3%'}} onClick={share} color='success'>Share</Button>

               
            </>
            }

            {tab == 4 &&
            <>
                <h6>Claims history({claims.length})</h6>
                <div className="table-responsive">
                <Table striped bordered hover>

                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Policy NO.</th>
                            <th scope="col">Product</th>
                            <th scope="col">Insurer</th>
                            <th scope="col">Loss</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {claims.map((val,index)=>{
                          
                            return(
                                <tr>
                                    <td>{index+1}</td>
                                    <td><button className='tableButton' value={val.id} onClick={toggle3}>{val.fname} {val.policyNumber}</button></td>
                                    <td>{val.product}</td>
                                    <td>{val.insurer}</td>
                                    <td>{val.loss}</td>
                                    <td>{val.status}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
            </>
            
            }
            
            <p onClick={close2} style={{color:'red',marginTop:'3%', textAlign:'right',cursor:'pointer'}}>Close</p>
        </div>
        
    </Modal>


    <Modal isOpen={modal3} toggle={toggle3}>
            <ModalHeader>Update claim</ModalHeader>
            <div className="modalContainer">
                <div className="modalMenu">
                    <p onClick={tab1}>Basic Info</p>
                    <p  onClick={tab2}>Documents</p>
                    <p  onClick={tab3}>Share</p>
                </div>
                {claimTab == 1 &&
                <>

                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Policy Number" onChange={(e)=>{setPolicynumber(e.target.value)}} disabled>
                    <Form.Control  className='inputSearch' value={policyNumber}  type="text" placeholder="Policy Number"  />
                </FloatingLabel>
               
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="client" disabled>
                    <Form.Control  className='inputSearch' value={client}  type="text" placeholder="Client" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="product" disabled>
                    <Form.Control  className='inputSearch' value={product}  type="text" placeholder="product" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="insurer" disabled>
                    <Form.Control  className='inputSearch' value={insurer}  type="text" placeholder="insurer" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Sum insured" disabled>
                    <Form.Control  className='inputSearch' value={sumInsured}  type="text" placeholder="Sum insured" />
                </FloatingLabel>
                
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Loss" disabled>
                    <Form.Control  className='inputSearch' value={loss}  type="text" placeholder="Loss" />
                </FloatingLabel>
                
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Status" onChange={(e)=>{setStatus(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Status</option>
                        <option value='Under Review'>Under Review</option>
                        <option value='Disbursement'>Disbursement</option>
                        <option value='Rejected'>Rejected</option>
                        <option value='Closed'>Closed</option>
                    </Form.Select>
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Date of Occurence" disabled>
                    <Form.Control  className='inputSearch' value={occurenceDate}  type="text" placeholder="Date of occurence" />
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingTextarea" label="Naration">
                <Form.Control 
                    as="textarea" 
                    className='inputSearchs' 
                    value={naration} 
                    placeholder="Naration" 
                    rows={10} 
            
                    disabled
                />
                </FloatingLabel>


                <div className="modalButtons">
                <Button color='success' style={{marginTop:'3%'}} onClick={updateClaim}>{updateText}</Button>
                <Button color='danger' style={{marginTop:'3%',marginLeft:"3%"}} onClick={removeClaim}>{deleteClaimtext}</Button>
                <Button color='danger' style={{marginTop:'3%',marginLeft:"3%"}} onClick={()=>setModal3(false)}>Close</Button>

                </div>

                </>
                
                }

                {claimTab == 2 &&
                <>
               
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Upload document" onChange={(e) => setFile(e.target.files[0])}>
                    <Form.Control  className='inputSearch'  type="file" placeholder="Upload document" />
                </FloatingLabel>
                <p>Claim documents</p>
                    {files.length > 0 ? (
                        <ul>
                        {files.map((file) => (
                            <li key={file.name}>
                            <a href={file.url} target="_blank" rel="noopener noreferrer">
                                {file.name}
                            </a>
                            </li>
                        ))}
                        </ul>
                    ) : (
                        <div>No Files Found</div>
                    )}
                <Button color='success' style={{marginTop:'3%'}} onClick={handleFileUpload}>Upload document</Button>
                </>
                }

                {claimTab == 3 &&
                 <>
                    <p>Share claim details with insurer</p>

                    <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Emai" onChange={(e)=>{setShareemail(e.target.value)}} >
                        <Form.Control  className='inputSearch' value={shareEmail}  type="text" placeholder="Email" />
                    </FloatingLabel>

                    <Button color='success' style={{marginTop:'3%'}} onClick={share}>Share</Button>

                 </>
                }
                
            </div>
        </Modal>

    </div>
  )
}
