import {React, useState, useEffect} from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Table from 'react-bootstrap/Table';
import { Button,Modal,ModalHeader } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

export default function Products() {
    document.title = "Products | Newjo Insurance Agency";

    const [products, setProducts] = useState([]);
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [product, setProduct] = useState('');
    const [code, setCode] = useState('');
    const [risk, setRisk] = useState('');
    const [risks, setRisks] = useState([]);
    const [submitText, setSubmittext] = useState('Submit Product')
    const [updateText, setUpdatetext] = useState('Save changes')
    const [deleteText, setDeletetext] = useState('Remove product')
    const [selectedProduct, setSelectedproduct] = useState('')


    useEffect(()=>{

        const fetchProducts =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getProducts');
            const data = await res.json();
            setProducts(data)

        }
        const fetchRiskclasses = async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getRiskclasses');
            const data = await res.json();
            setRisks(data)
        }

        fetchRiskclasses();

        fetchProducts()

    },[])

    const fetchProducts =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getProducts');
            const data = await res.json();
            setProducts(data)

        }

    const toggle =()=>{
        setModal(!modal)
    }

    const submitProduct =async()=>{
        if(product !=='' && code !== '' && risk !== '' ){
            setSubmittext('Please wait...')
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/submitProduct',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({product,code,risk}),
            credentials:"include"
        })

        const data = await res.json();
            const {success} = data;
            if(success == true){
                setSubmittext('Submit Product')
                toast.success('Product added');
                fetchProducts();
                setModal(false)
            }
            else{
                setSubmittext('Submit Product')
                toast.error('Something went wrong!')
            }

        }
        else{
            toast.error("All fields are required")
        }
    }

    const toggle2 =async(e)=>{
        const id = e.target.value;
        setSelectedproduct(id)
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getThisproduct?id=${id}`);
        const data = await res.json();
        setRisk(data.risklass);
        setCode(data.ProductCode)
        setProduct(data.ProductDescription)

        setModal2(!modal2)

    }


    const updateProduct =async()=>{
        setUpdatetext('Please wait...')
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/updateProduct',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({risk,code,selectedProduct,product}),
            credentials:"include"
        })

        const data = await res.json();
            const {success} = data;
            if(success == true){
                setUpdatetext('Save changes')
                toast.success('Changes saved');
                fetchProducts();
                setModal2(false)
            }
            else{
                setUpdatetext('Save changes')
                toast.error('Something went wrong!')
            }
    }


    const removeProduct =async()=>{
        setDeletetext('Please wait')
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/deleteProduct?id=${selectedProduct}`);
        const data = await res.json();
        const {success} = data;
        if(success == true){
                setUpdatetext('Remove product')
                toast.success('Product removed');
                fetchProducts();
                setModal2(false)
            }
            else{
                setUpdatetext('Remove product')
                toast.error('Something went wrong!')
            }

    }



  return (
    <div className='pagesContainer'>
        <ToastContainer />
                <Breadcrumbs title="Newjo" breadcrumbItem='Products' />
                <div className="card">
                <div className='addButton'>
                    <Button  color='success' onClick={toggle} >+ New Product</Button>
                </div>
                <div className="table-responsive">
                <Table striped bordered hover>

                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Product</th>
                            <th scope="col">Code</th>
                            <th scope="col">Class</th>
                            <th scope="col">Number of policies</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((val,index)=>{
                        
                            return(
                                <tr>
                                    <td>{index+1}</td>
                                   
                                    <td><button className='tableButton' value={val.id} onClick={toggle2}  >{val.ProductDescription}</button></td>
                                    <td>{val.ProductCode}</td>
                                    <td>{val.riskClass}</td>
                                    <td>{val.NumOfPolicies}</td>
                                   
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
                </div>

            <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader>New Product</ModalHeader>
            <div className="modalContainer">
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Product" onChange={(e)=>{setProduct(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={product}  type="text" placeholder="Product" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Product code" onChange={(e)=>{setCode(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={code}  type="text" placeholder="Product code" />
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Risk class" onChange={(e)=>{setRisk(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select risk class</option>
                        {risks.map((val)=>{
                            return(
                                <option value={val.RiskCode}>{val.RiskClass}</option>
                            )
                            
                        })}
           
                    </Form.Select>
                </FloatingLabel>
                
                <Button color='success' style={{marginTop:'3%'}} onClick={submitProduct}>{submitText}</Button>
            </div>
        </Modal>


        <Modal isOpen={modal2} toggle={toggle2}>
            <ModalHeader>Edit Product</ModalHeader>
            <div className="modalContainer">
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Product" onChange={(e)=>{setProduct(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={product}  type="text" placeholder="Product" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Product code" onChange={(e)=>{setCode(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={code}  type="text" placeholder="Product code" />
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Risk class" onChange={(e)=>{setRisk(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>{risk}</option>
                        {risks.map((val)=>{
                            return(
                                <option value={val.RiskCode}>{val.RiskClass}</option>
                            )
                            
                        })}
           
                    </Form.Select>
                </FloatingLabel>
                
                <div className="modalButtons">
                <Button color='success' style={{marginTop:'3%'}} onClick={updateProduct}>{updateText}</Button>
                <Button color='danger' style={{marginTop:'3%',marginLeft:"3%"}} onClick={removeProduct}>{deleteText}</Button>
                <Button color='danger' style={{marginTop:'3%',marginLeft:"3%"}} onClick={()=>setModal2(false)}>Close</Button>

                </div>
            </div>
        </Modal>
    </div>
  )
}
