import {React, useState, useEffect} from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Table from 'react-bootstrap/Table';
import { Button,Modal,ModalHeader } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

export default function Templates() {
    document.title = "Settings-Templates | Newjo Insurance Agency";

    const [templates, setTemplates] = useState([]);
    const [type, setType] = useState('');
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [submitText, setSubmittext] = useState('Submit template')
    const [updateText, setUpdatetext] = useState('Save changes')
    const [deleteText, setDeletetext] = useState('Remove template')
    const [modal, setModal] = useState(false);
    const [modal2,setModal2] = useState(false);
    const [selectedTemplate, setSelectedtemplate] = useState('')



    useEffect(()=>{
        const fetchTemplates =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getTemplates');
            const data = await res.json();
            setTemplates(data)

        }

        
        fetchTemplates();
        

    },[])

    const fetchTemplates =async()=>{
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/getTemplates');
        const data = await res.json();
        setTemplates(data)

    }

    const submitTemplate =async()=>{
        if(title !== '' && message !== '' && type !== ''){
            setSubmittext('Please wait...')
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/submitTemplate',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({message,type,title}),
            credentials:"include"
        })

        const data = await res.json();
            const {success} = data;
            if(success == true){
                setSubmittext('Submit template')
                toast.success('Data added');
                fetchTemplates();
                setModal(false)
            }
            else{
                setSubmittext('Submit template')
                toast.error('Something went wrong!')
            }

        }
        else{
            toast.error("All fields are required")
        }
    }


    const toggle =()=>{
        setModal(!modal)
    }

    const toggle2 =async(e)=>{
        const id = e.target.value;
        setSelectedtemplate(id)
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getThistemplate?id=${id}`);
        const data = await res.json();
        setMessage(data.content)
        setTitle(data.title)
        setType(data.type)
        setModal2(!modal2)

    }

    const updateTemplate =async()=>{
        setUpdatetext('Please wait...')
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/updateTemplate',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({type,message,title,selectedTemplate}),
            credentials:"include"
        })

        const data = await res.json();
            const {success} = data;
            if(success == true){
                setUpdatetext('Save changes')
                toast.success('Changes saved');
                fetchTemplates();
                setModal2(false)
            }
            else{
                setUpdatetext('Save changes')
                toast.error('Something went wrong!')
            }
    }

    const removeTemplate =async()=>{
        setDeletetext('Please wait')
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/deleteTemplate?id=${selectedTemplate}`);
        const data = await res.json();
        const {success} = data;
        if(success == true){
                setDeletetext('Remove template')
                toast.success('Template removed');
                fetchTemplates();
                setModal2(false)
            }
            else{
                setDeletetext('Remove template')
                toast.error('Something went wrong!')
            }

    }





  return (
    <div className='pagesContainer'>
                 <ToastContainer />
          <Breadcrumbs title="Newjo" breadcrumbItem='Templates' />
          <div className="card">
          <div className='addButton'>
            <Button  color='success' onClick={toggle}  >+ New Duration</Button>
        </div>
          <div className="table-responsive">
                <Table striped bordered hover>

                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Title</th>
                            <th scope="col">Type</th>
                            <th scope="col">message</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {templates.map((val,index)=>{
                            
                        return(
                        <tr>
                            <td>{index+1}</td>       
                            <td><button className='tableButton' value={val.id} onClick={toggle2} >{val.title}</button></td>
                            <td>{val.type}</td>
                            <td>{val.content}</td>
                        </tr>
                            )

                            
                           


                            
                        })}
                    </tbody>
                </Table>
            </div>
          </div>


          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader>New template</ModalHeader>
            <div className="modalContainer">

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Template type" onChange={(e)=>{setType(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select template type</option>
                        <option value='SMS'>SMS</option>
                        <option value='Email'>Email</option>
                        

                    </Form.Select>
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Template title" onChange={(e)=>{setTitle(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={title}  type="text" placeholder="Template title" />
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingTextarea" label="Message">
                <Form.Control 
                    as="textarea" 
                    className='inputSearch' 
                    value={message} 
                    placeholder="Mesage" 
                    rows={10} 
                    onChange={(e) => setMessage(e.target.value)} 
                />
                </FloatingLabel>


                
                <Button color='success' style={{marginTop:'3%'}} onClick={submitTemplate}>{submitText}</Button>
            </div>
        </Modal>

        <Modal isOpen={modal2} toggle={toggle2}>
            <ModalHeader>Edit template</ModalHeader>
            <div className="modalContainer">

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Template type" onChange={(e)=>{setType(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>{type}</option>
                        <option value='SMS'>SMS</option>
                        <option value='Email'>Email</option>
                        

                    </Form.Select>
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Template title" onChange={(e)=>{setTitle(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={title}  type="text" placeholder="Template title" />
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingTextarea" label="Message">
                <Form.Control 
                    as="textarea" 
                    className='inputSearch' 
                    value={message} 
                    placeholder="Mesage" 
                    rows={10} 
                    onChange={(e) => setMessage(e.target.value)} 
                />
                </FloatingLabel>


                
                <div className="modalButtons">
                <Button color='success' style={{marginTop:'3%'}} onClick={updateTemplate}>{updateText}</Button>
                <Button color='danger' style={{marginTop:'3%',marginLeft:"3%"}} onClick={removeTemplate}>{deleteText}</Button>
                <Button color='danger' style={{marginTop:'3%',marginLeft:"3%"}} onClick={()=>setModal2(false)}>Close</Button>

                </div>
            </div>
        </Modal>

    </div>
  )
}
