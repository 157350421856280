import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../withRouter";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [email, setEmail] = useState('')
  const [username, setusername] = useState("");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/profile', {
          credentials: 'include', // to send cookies
        });
        const data = await res.json();

        if (data.valid) {
          setEmail(data.email)
        } else {}
      } catch (error) {} 
    };

    fetchProfile();
  }, []);

  //get store id
  useEffect(()=>{
   const fetchData = async()=>{
      try{
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getUserdetails?email=${email}`);
        const data = await res.json();
        setusername(data.fname)
      }
      catch(err){}
   }

   if(email){
    fetchData();
   }
   
  },[email])


  



  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src='/user.webp'
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-2">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
      
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="ri-shut-down-line align-middle me-2 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
