import PropTypes from "prop-types";
import React, { useEffect,useState } from "react";
import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

// Formik validation


const Login = props => {
  document.title = "Login | Newjo Insurance Agency";
  const[email, setEmail] = useState('');
  const[password, setPassword] = useState('')
  const navigate = useNavigate();
  const[modal, setModal] = useState('')
  const[newPassword,setNewpassword] = useState('');
  const[confirmPassword, setConfirmpassword] = useState('');
  const[role, setRole] = useState('')


  const dispatch = useDispatch();

  
  useEffect(() => {
    document.body.className = "bg-pattern";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  const login = async () => {
    if (email !== '' && password !== '') {
      try {
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
          credentials: 'include'
        });
  
        const data = await res.json(); // Parse JSON response
        console.log('Response:', data); // Log full response to debug
  
        // Ensure the response has the properties you expect
        const { exists, Login,role } = data;
        
  
        if (exists === false) {
          toast.error('Email address does not exist.');
        } else if (Login === false) {
          toast.error('Incorrect email/password combination');
        } else if (Login === true) {
          navigate('/dashboard')
          
        } else {
          toast.error('Unexpected response from the server.');
        }
  
      } catch (err) {
        console.error('Error:', err);  // Log the error for debugging
        toast.error('Cannot connect to the server. Please try later!');
      }
    } else {
      toast.error('Please fill your email and password!');
    }
  };

  return (
    <React.Fragment>
    
    <div className="bg-overlay"></div>
    <div className="account-pages my-5 pt-5">
      <Container>
      <ToastContainer />
        <Row className="justify-content-center">
          <Col lg={6} md={8} xl={4}>
            <Card>
              <CardBody className="p-4">
                <div>
                  <div className="text-center">
                   <h4>Newjo Insurance Agency</h4>
                  </div>
                
                  <p className="mb-5 text-center">
                    Sign in to continue to Dashboard.
                  </p>
                 
                   
                    <Row>
                      <Col md={12}>
                        <div className="mb-4">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={(e)=>{setEmail(e.target.value)}}
                          value={email}

                        />
                      
                        </div>
                        <div className="mb-4">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            value={password}
                            type="password"
                            placeholder="Enter Password"
                            onChange={(e)=>{setPassword(e.target.value)}}
                           
                          />
                          
                        </div>

                        <Row>
                          <Col>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <label
                                className="form-label form-check-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </label>
                            </div>
                          </Col>
                          <Col className="col-7">
                            <div className="text-md-end mt-3 mt-md-0">
                              <Link
                                
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock"></i> Forgot your
                                password?
                              </Link>
                            </div>
                          </Col>
                        </Row>
                        <div className="d-grid mt-4">
                          <button
                          style={{backgroundColor:'red',color:"white",fontWeight:"500"}}
                            className="btn waves-effect waves-light"
                            type="submit"
                            onClick={login}
                          >
                            Log In
                          </button>
                        </div>
                        <div className="mt-4 text-center">
                 

                      
                    </div>

                      </Col>
                    </Row>
                  
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
            
              <p className="text-white-50">
                © {new Date().getFullYear()} Newjo Insurance Agency. Developed by Uidevlabs Technologies.
               
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
