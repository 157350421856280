import {React,useState,useEffect} from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Table from 'react-bootstrap/Table';
import { Button,Modal,ModalHeader } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

export default function Claims() {

    document.title = "Claims | Newjo Insurance Agency";

    const [claims, setClaims] = useState([]);
    const [policyNumber, setPolicynumber] = useState('');
    const [client, setClient] = useState('');
    const [product, setProduct] = useState('');
    const [insurer, setInsurer] = useState('');
    const [status, setStatus] = useState('');
    const [sumInsured, setSuminsured] = useState('')
    const [loss, setLoss] = useState('');
    const [naration, setNaration] = useState('');
    const [occurenceDate, setOccurencedate] = useState('')
    const [selectedClaim, setSelectedclaim] = useState('');
    const [submitText, setSubmittext] = useState('Submit claim')
    const [updateText, setUpdatetext] = useState('Save changes')
    const [deleteText, setDeletetext] = useState('Remove claim')
    const [modal, setModal] = useState(false)
    const [modal2, setModal2] = useState(false)
    const [searchText, setSearchtext] = useState('Search policy')
    const [identification, setIdentification] = useState('')
    const [email, setEmail] = useState('')
    const [file, setFile] = useState(null);
    const [tab,setTab] = useState(1);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [shareEmail, setShareemail] = useState('')

    useEffect(() => {
        const fetchFiles = async () => {
          try {
            const response = await fetch(`https://panel.yunshuglobal.com:3060/api/files/claims/${email}`);
            if (!response.ok) {
              if (response.status === 404) {
                setError("No Files Found.");
              } else {
                setError("Failed to fetch files.");
              }
            } else {
              const data = await response.json();
              setFiles(data.files);
            }
          } catch (err) {
            setError("An error occurred.");
            console.error(err);
          } finally {
            setLoading(false);
          }
        };
    
        fetchFiles();
      }, [email]);

      const fetchFiles = async () => {
        try {
          const response = await fetch(`https://panel.yunshuglobal.com:3060/api/files/claims/${email}`);
          if (!response.ok) {
            if (response.status === 404) {
              setError("No Files Found.");
            } else {
              setError("Failed to fetch files.");
            }
          } else {
            const data = await response.json();
            setFiles(data.files);
          }
        } catch (err) {
          setError("An error occurred.");
          console.error(err);
        } finally {
          setLoading(false);
        }
      };
    
    

    const tab1 =()=>{
        setTab(1)
    }
    const tab2 =()=>{
        setTab(2)
    }
    const tab3 =()=>{
        setTab(3)
    }


    const handleFileUpload = async (e) => {
        e.preventDefault();
    
        if (!file || !email) {
          alert("Please provide both email and file.");
          return;
        }
    
        const formData = new FormData();
        formData.append("email", email);
        formData.append("file", file); // Attach the file
    
        try {
          const response = await fetch("https://panel.yunshuglobal.com:3060/api/uploadClaimdocuments", {
            method: "POST",
            body: formData,
            credentials:'include'
          });
          
    
          const result = await response.json();
    
          if (response.ok) {
            fetchFiles();
            toast.success('File has been upload')
          } else {
           toast.error('File failed to upload')
          }
        } catch (error) {
            toast.error('Something went wrong')
        }
      };


    useEffect(()=>{
        const fetchClaims =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getClaims');
            const data = await res.json();
            setClaims(data)

        }

        
        fetchClaims();
        

    },[])

    const fetchClaims =async()=>{
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/getClaims');
        const data = await res.json();
        setClaims(data)

    }

    useEffect(()=>{
        const getEmail =async()=>{
            const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getClientemail?id=${identification}`);
            const data = await res.json();
            setEmail(data.email)

            
        }

        getEmail();
    },[identification])


    const submitClaim =async()=>{
        if(loss !== '' && naration !== '' && policyNumber !== ''){
            setSubmittext('Please wait...')
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/submitClaim',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({policyNumber,client,product,insurer,sumInsured,loss,naration,occurenceDate,identification}),
            credentials:"include"
        })

        const data = await res.json();
            const {success} = data;
            if(success == true){
                setSubmittext('Submit claim')
                toast.success('Data added');
                fetchClaims();
                close1();
                setModal(false)
            }
            else{
                setSubmittext('Submit claim')
                toast.error('Something went wrong!')
            }

        }
        else{
            toast.error("All fields are required")
        }
    }

    const toggle =()=>{
        setModal(!modal)
    }

    const handleDateChange = (e) => {
        const dateTime = e.target.value;
        const millisecondsSinceEpoch = new Date(dateTime).getTime();
        setOccurencedate(millisecondsSinceEpoch);
    
      };

    const getPolicydetails =async()=>{
        if(policyNumber !== ''){
            setSearchtext('Please wait')
            const res = await fetch(`https://panel.yunshuglobal.com:3060/api/searchpolicy?policy=${policyNumber}`);
            const data = await res.json();
            const {success, exists} = data;

            if(success == true && exists == true){
                setClient(data.client);
                setInsurer(data.insurer);
                setProduct(data.product);
                setSuminsured(data.sumInsured);
                setStatus(data.status);
                setIdentification(data.identification)

                setSearchtext('Search policy')

            }
            if(success == true && exists == false){
                setSearchtext('Search policy')
                toast.error('The policy number does not exist')

            }
            if(success == false && exists == false){
                setSearchtext('Search policy')
                toast.error('Something went wrong')
            }

        }
        else{
            toast.error('Please enter policy number')
        }
        

    }

    const toggle2 =async(e)=>{
        const id = e.target.value;
        setSelectedclaim(id)
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getThisclaim?id=${id}`);
        const data = await res.json();
        setClient(data.client);
        setInsurer(data.insurer);
        setProduct(data.product);
        setSuminsured(data.sumInsured);
        setStatus(data.status);
        setPolicynumber(data.policyNumber)
        setLoss(data.loss)
        setNaration(data.naration)
        setIdentification(data.identification)
        setOccurencedate(new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(data.date))
        setModal2(!modal2)

    }

    const updateClaim =async()=>{
        setUpdatetext('Please wait...')
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/updateClaim',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({status,selectedClaim}),
            credentials:"include"
        })

        const data = await res.json();
            const {success} = data;
            if(success == true){
                setUpdatetext('Save changes')
                toast.success('Changes saved');
                fetchClaims();
                setModal2(false)
            }
            else{
                setUpdatetext('Save changes')
                toast.error('Something went wrong!')
            }
    }

    const removeClaim =async()=>{
        setDeletetext('Please wait')
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/deleteClaim?id=${selectedClaim}`);
        const data = await res.json();
        const {success} = data;
        if(success == true){
                setDeletetext('Remove claim')
                toast.success('Claim removed');
                fetchClaims();
                setModal2(false)
            }
            else{
                setDeletetext('Remove claim')
                toast.error('Something went wrong!')
            }

    }

    const share=()=>{
        toast.error('Sorry! Email gateway is not integrated. Contact support')
    }

    const close1=()=>{
        setPolicynumber('');
        setClient('');
        setEmail('');
        setIdentification('')
        setProduct('');
        setInsurer('');
        setSuminsured('');
        setOccurencedate('');
        setStatus('')
        setLoss('');
        setNaration('')
        setFiles([])
        setModal(false)
    }

    const close2=()=>{
        setPolicynumber('');
        setClient('');
        setEmail('');
        setIdentification('')
        setProduct('');
        setInsurer('');
        setSuminsured('');
        setOccurencedate('');
        setStatus('')
        setLoss('');
        setNaration('')
        setFiles([])
        setModal2(false)
    }


  return (
    <div className='pagesContainer'>
          <ToastContainer />
          <Breadcrumbs title="Newjo" breadcrumbItem='Claims' />
          <div className="card">
          <div className='addButton'>
            <Button  color='success' onClick={toggle}  >+ Report a claim</Button>
        </div>
          <div className="table-responsive">
                <Table striped bordered hover>

                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Policy Number</th>
                            <th scope="col">Product</th>
                            <th scope="col">Client</th>
                            <th scope="col">Date of Occurence</th>
                            <th scope="col">Status</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {claims.map((val,index)=>{
                            const date = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.date)
                        return(
                        <tr>
                            <td>{index+1}</td>       
                            <td><button className='tableButton' onClick={toggle2} value={val.id} >{val.policyNumber}</button></td>
                            <td>{val.product}</td>
                            <td>{val.client}</td>
                            <td>{date}</td>
                            <td>{val.status}</td>
                            
                        </tr>
                            )

                            
                           


                            
                        })}
                    </tbody>
                </Table>
            </div>
          </div>



          
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader>Report a claim</ModalHeader>
            <div className="modalContainer">


                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Policy Number" onChange={(e)=>{setPolicynumber(e.target.value)}}>
                    <Form.Control  className='inputSearch' value={policyNumber}  type="text" placeholder="Policy Number" />
                </FloatingLabel>
                <Button style={{marginTop:'2%'}} onClick={getPolicydetails}>{searchText}</Button>
               
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="client" disabled>
                    <Form.Control  className='inputSearch' value={client}  type="text" placeholder="Client" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="product" disabled>
                    <Form.Control  className='inputSearch' value={product}  type="text" placeholder="product" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="insurer" disabled>
                    <Form.Control  className='inputSearch' value={insurer}  type="text" placeholder="insurer" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Sum insured" disabled>
                    <Form.Control  className='inputSearch' value={sumInsured}  type="text" placeholder="Sum insured" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Status" disabled>
                    <Form.Control  className='inputSearch' value={status}  type="text" placeholder="Status" />
                </FloatingLabel>
                
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Type of loss" onChange={(e)=>{setLoss(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select type</option>
                        <option value='Accident'>Accident</option>
                        <option value='Theft'>Theft</option>
                        <option value='Fire'>Fire</option>
                    </Form.Select>
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Date of occurence" onChange={handleDateChange}>
                    <Form.Control  className='inputSearch' value={occurenceDate}  type="datetime-local" placeholder="Template title" />
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingTextarea" label="Naration">
                <Form.Control 
                    as="textarea" 
                    className='inputSearchs' 
                    value={naration} 
                    placeholder="Naration" 
                    rows={10} 
                    onChange={(e) => setNaration(e.target.value)} 
                />
                </FloatingLabel>


                <div className="modalButtons">
                <Button color='success' style={{marginTop:'3%'}} onClick={submitClaim}>{submitText}</Button>
                <Button color='danger' style={{marginTop:'3%',marginLeft:"3%"}} onClick={close1}>Close</Button>
                </div>
                
            </div>
        </Modal>


        <Modal isOpen={modal2} toggle={toggle2}>
            <ModalHeader>Update claim</ModalHeader>
            <div className="modalContainer">
                <div className="modalMenu">
                    <p onClick={tab1}>Basic Info</p>
                    <p  onClick={tab2}>Documents</p>
                    <p  onClick={tab3}>Share</p>
                </div>
                {tab == 1 &&
                <>

                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Policy Number" onChange={(e)=>{setPolicynumber(e.target.value)}} disabled>
                    <Form.Control  className='inputSearch' value={policyNumber}  type="text" placeholder="Policy Number"  />
                </FloatingLabel>
               
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="client" disabled>
                    <Form.Control  className='inputSearch' value={client}  type="text" placeholder="Client" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="product" disabled>
                    <Form.Control  className='inputSearch' value={product}  type="text" placeholder="product" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="insurer" disabled>
                    <Form.Control  className='inputSearch' value={insurer}  type="text" placeholder="insurer" />
                </FloatingLabel>
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Sum insured" disabled>
                    <Form.Control  className='inputSearch' value={sumInsured}  type="text" placeholder="Sum insured" />
                </FloatingLabel>
                
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Loss" disabled>
                    <Form.Control  className='inputSearch' value={loss}  type="text" placeholder="Loss" />
                </FloatingLabel>
                
                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Status" onChange={(e)=>{setStatus(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Status</option>
                        <option value='Under Review'>Under Review</option>
                        <option value='Disbursement'>Disbursement</option>
                        <option value='Rejected'>Rejected</option>
                        <option value='Closed'>Closed</option>
                    </Form.Select>
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Date of Occurence" disabled>
                    <Form.Control  className='inputSearch' value={occurenceDate}  type="text" placeholder="Date of occurence" />
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingTextarea" label="Naration">
                <Form.Control 
                    as="textarea" 
                    className='inputSearchs' 
                    value={naration} 
                    placeholder="Naration" 
                    rows={10} 
            
                    disabled
                />
                </FloatingLabel>


                <div className="modalButtons">
                <Button color='success' style={{marginTop:'3%'}} onClick={updateClaim}>{updateText}</Button>
                <Button color='danger' style={{marginTop:'3%',marginLeft:"3%"}} onClick={removeClaim}>{deleteText}</Button>
                <Button color='danger' style={{marginTop:'3%',marginLeft:"3%"}} onClick={close2}>Close</Button>

                </div>

                </>
                
                }

                {tab == 2 &&
                <>
               
                <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Upload document" onChange={(e) => setFile(e.target.files[0])}>
                    <Form.Control  className='inputSearch'  type="file" placeholder="Upload document" />
                </FloatingLabel>
                <p>Claim documents</p>
                    {files.length > 0 ? (
                        <ul>
                        {files.map((file) => (
                            <li key={file.name}>
                            <a href={file.url} target="_blank" rel="noopener noreferrer">
                                {file.name}
                            </a>
                            </li>
                        ))}
                        </ul>
                    ) : (
                        <div>No Files Found</div>
                    )}
                <Button color='success' style={{marginTop:'3%'}} onClick={handleFileUpload}>Upload document</Button>
                </>
                }

                {tab == 3 &&
                 <>
                    <p>Share claim details with insurer</p>

                    <FloatingLabel className='formInputs' controlId="floatingInputGrid" label="Emai" onChange={(e)=>{setShareemail(e.target.value)}} >
                        <Form.Control  className='inputSearch' value={shareEmail}  type="text" placeholder="Email" />
                    </FloatingLabel>

                    <Button color='success' style={{marginTop:'3%'}} onClick={share}>Share</Button>

                 </>
                }
                
            </div>
        </Modal>

    </div>
  )
}
