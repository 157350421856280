import React from 'react';
import { useState,useEffect } from 'react';
import LineColumnArea from './LineColumnArea';

import {
    Card,
    CardBody,
    Col,
    Row
} from "reactstrap";




const OverView = () => {
    const [OverViewData, setOverviewdata] = useState([])

    useEffect(()=>{
        const getData =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getOverviewdata')
            const data = await res.json();
            setOverviewdata(data)
        }

        getData();

    },[])

    return (
        <React.Fragment>
            <Col xl={8}>
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <h5 className="card-title">Overview</h5>
                            </div>
                            <div className="flex-shrink-0">
                                <div>
                                    <button type="button" className="btn btn-soft-secondary btn-sm me-1">
                                        ALL
                                    </button>
                                    <button type="button" className="btn btn-soft-primary btn-sm me-1">
                                        1M
                                    </button>
                                    <button type="button" className="btn btn-soft-secondary btn-sm me-1">
                                        6M
                                    </button>
                                    <button type="button" className="btn btn-soft-secondary btn-sm me-1 active">
                                        1Y
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <LineColumnArea />
                        </div>
                    </CardBody>
                    <CardBody className="border-top">
                        <div className="text-muted text-center">
                            <Row>
                                {OverViewData.map((item, key) => (<Col md={4} key={key} className="border-end">
                                    <div>
                                        {item.title == 'Active' &&
                                            <p className="mb-2"><i className={"mdi mdi-circle font-size-12 me-1 text-success"}></i> {item.title}</p>
                                        }
                                        {item.title == 'pending' &&
                                            <p className="mb-2"><i className={"mdi mdi-circle font-size-12 me-1 text-warning"}></i> {item.title}</p>
                                        }
                                        {item.title == 'Expired' &&
                                            <p className="mb-2"><i className={"mdi mdi-circle font-size-12 me-1 text-info"}></i> {item.title}</p>
                                        }
                                        {item.title == 'Terminated' &&
                                            <p className="mb-2"><i className={"mdi mdi-circle font-size-12 me-1 text-danger"}></i> {item.title}</p>
                                        }
                                        
                                        <h5 className="font-size-16 mb-0">{item.total} Policies </h5>
                                    </div>
                                </Col>))}
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default OverView;