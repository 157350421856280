import {React,useState,useEffect} from "react";
import { Navigate, Route } from "react-router-dom";

import { useProfile } from "../Hooks/UserHooks";


const AuthProtected = (props) => {
  const { userProfile, loading } = useProfile();

  // If loading, you can display a loader or simply return null
  if (loading) {
    return <div>Loading...</div>;
  }

  // If no user profile, redirect to login
  if (!userProfile) {
    return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />;
  }

  // Otherwise, render the protected component
  return <>{props.children}</>;
};

export { AuthProtected};
