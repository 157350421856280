import {React, useState, useEffect} from 'react'
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Table from 'react-bootstrap/Table';
import { Button,Modal,ModalHeader } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';


export default function Durantions() {
    document.title = "Settings-Durations | Newjo Insurance Agency";

    const [durations, setDurations] = useState([]);
    const [covers, setCovers] = useState([]);
    const [duration,setDuration] = useState('');
    const [cover,setCover] = useState('');
    const [selectedDuration, setSelectedduration] = useState('');
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [submitText, setSubmittext] = useState('Submit durations')
    const [updateText, setUpdatetext] = useState('Save changes')
    const [deleteText, setDeletetext] = useState('Remove duration')
    const [days, setDays] = useState('')
    const [filterDuration, setFilterduration] = useState('');
    const [filterCover, setFiltercover] = useState('')



    useEffect(()=>{
        const fetchDurations =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getDurations');
            const data = await res.json();
            setDurations(data)

        }

        const fetchCovers =async()=>{
            const res = await fetch('https://panel.yunshuglobal.com:3060/api/getCovertypes');
            const data = await res.json();
            setCovers(data)

        }

        fetchDurations();
        fetchCovers()

    },[])

    const fetchDurations =async()=>{
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/getDurations');
        const data = await res.json();
        setDurations(data)

    }

    const submitDuration =async()=>{
        if(duration !== '' && cover !== ''){
            setSubmittext('Please wait...')
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/submitDurations',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({duration,cover,days}),
            credentials:"include"
        })

        const data = await res.json();
            const {success} = data;
            if(success == true){
                setSubmittext('Submit durations')
                toast.success('Data added');
                fetchDurations();
                setModal(false)
            }
            else{
                setSubmittext('Submit durations')
                toast.error('Something went wrong!')
            }

        }
        else{
            toast.error("All fields are required")
        }
    }

    const toggle =()=>{
        setModal(!modal)
    }

    useEffect(()=>{
        if(duration == 'Annually'){
            setDays('365')
        }
        else if(duration == 'Monthly'){
            setDays('30')
        }
        else if(duration == 'Weekly'){
            setDays('7')
        }

    },[duration])


    const toggle2 =async(e)=>{
        const id = e.target.value;
        setSelectedduration(id)
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/getThisduration?id=${id}`);
        const data = await res.json();
        setDuration(data.duration);
        setCover(data.cover)

        setModal2(!modal2)

    }

    const updateDuration =async()=>{
        setUpdatetext('Please wait...')
        const res = await fetch('https://panel.yunshuglobal.com:3060/api/updateDuration',{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({cover,duration,days,selectedDuration}),
            credentials:"include"
        })

        const data = await res.json();
            const {success} = data;
            if(success == true){
                setUpdatetext('Save changes')
                toast.success('Changes saved');
                fetchDurations();
                setModal2(false)
            }
            else{
                setUpdatetext('Save changes')
                toast.error('Something went wrong!')
            }
    }


    const removeDuration =async()=>{
        setDeletetext('Please wait')
        const res = await fetch(`https://panel.yunshuglobal.com:3060/api/deleteDuration?id=${selectedDuration}`);
        const data = await res.json();
        const {success} = data;
        if(success == true){
                setUpdatetext('Remove duration')
                toast.success('Duration removed');
                fetchDurations();
                setModal2(false)
            }
            else{
                setUpdatetext('Remove durations')
                toast.error('Something went wrong!')
            }

    }




  return (
    <div className='pagesContainer'>
    <ToastContainer />
          <Breadcrumbs title="Newjo" breadcrumbItem='Staff' />
          <div className="card">
          <div className='addButton'>
            <Button  color='success' onClick={toggle}  >+ New Duration</Button>
        </div>
        <div className="filterTabs">
            <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Duration" onChange={(e)=>{setFilterduration(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select duration</option>
                        <option value=''>All</option>
                        <option value='Annually'>Annually</option>
                        <option value='Monthly'>Monthly</option>
                        <option value='Weekly'>Weekly</option>

                    </Form.Select>
            </FloatingLabel>
            <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Cover" onChange={(e)=>{setFiltercover(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select cover</option>
                        <option value=''>All</option>
                       {covers.map((val)=>{
                        return(
                            <option value={val.CoverTypeDescription}>{val.CoverTypeDescription}</option>
                        )
                       })}

                    </Form.Select>
                </FloatingLabel>

        </div>
        <div className="table-responsive">
                <Table striped bordered hover>

                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Cover</th>
                            <th scope="col">Duration</th>
                            <th scope="col">Days</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {durations.map((val,index)=>{
                            if(filterCover == '' && filterDuration == ''){
                                return(
                                <tr>
                                    <td>{index+1}</td>       
                                    <td><button className='tableButton' value={val.id} onClick={toggle2}  >{val.cover}</button></td>
                                    <td>{val.duration}</td>
                                    <td>{val.days}</td>
                                </tr>
                            )

                            }
                            if(filterCover == val.cover && filterDuration == ''){
                                return(
                                <tr>
                                    <td>{index+1}</td>       
                                    <td><button className='tableButton' value={val.id} onClick={toggle2}  >{val.cover}</button></td>
                                    <td>{val.duration}</td>
                                    <td>{val.days}</td>
                                </tr>
                            )

                            }
                            if(filterCover == val.cover && filterDuration == val.duration){
                                return(
                                <tr>
                                    <td>{index+1}</td>       
                                    <td><button className='tableButton' onClick={toggle2} value={val.id}  >{val.cover}</button></td>
                                    <td>{val.duration}</td>
                                    <td>{val.days}</td>
                                </tr>
                            )

                            }
                            if(filterCover == '' && filterDuration == val.duration){
                                return(
                                <tr>
                                    <td>{index+1}</td>       
                                    <td><button className='tableButton' onClick={toggle2} value={val.id}  >{val.cover}</button></td>
                                    <td>{val.duration}</td>
                                    <td>{val.days}</td>
                                </tr>
                            )

                            }


                            
                        })}
                    </tbody>
                </Table>
            </div>
          </div>

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader>New duration</ModalHeader>
            <div className="modalContainer">

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Duration" onChange={(e)=>{setDuration(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select duration</option>
                        <option value='Annually'>Annually</option>
                        <option value='Monthly'>Monthly</option>
                        <option value='Weekly'>Weekly</option>

                    </Form.Select>
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Cover" onChange={(e)=>{setCover(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>Select cover</option>
                       {covers.map((val)=>{
                        return(
                            <option value={val.CoverTypeDescription}>{val.CoverTypeDescription}</option>
                        )
                       })}

                    </Form.Select>
                </FloatingLabel>

                

               
                
                <Button color='success' style={{marginTop:'3%'}} onClick={submitDuration}>{submitText}</Button>
            </div>
        </Modal>


        <Modal isOpen={modal2} toggle={toggle2}>
            <ModalHeader>Edit duration</ModalHeader>
            <div className="modalContainer">

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Duration" onChange={(e)=>{setDuration(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>{duration}</option>
                        <option value='Annually'>Annually</option>
                        <option value='Monthly'>Monthly</option>
                        <option value='Weekly'>Weekly</option>

                    </Form.Select>
                </FloatingLabel>

                <FloatingLabel className='formInputs' controlId="floatingSelectGrid" label="Cover" onChange={(e)=>{setCover(e.target.value)}}>
                    <Form.Select className='inputSearch'>
                        <option selected disabled>{cover}</option>
                       {covers.map((val)=>{
                        return(
                            <option value={val.CoverTypeDescription}>{val.CoverTypeDescription}</option>
                        )
                       })}

                    </Form.Select>
                </FloatingLabel>

                

               
                
                <div className="modalButtons">
                <Button color='success' style={{marginTop:'3%'}} onClick={updateDuration}>{updateText}</Button>
                <Button color='danger' style={{marginTop:'3%',marginLeft:"3%"}} onClick={removeDuration}>{deleteText}</Button>
                <Button color='danger' style={{marginTop:'3%',marginLeft:"3%"}} onClick={()=>setModal2(false)}>Close</Button>

                </div>
            </div>
        </Modal>
    </div>
  )
}
